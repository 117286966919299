export default ({
    MainPage: true,
    ListPage: false,
    FavoritesPage: false,
    BalancePage: false,
    HistoryPage: false,
    RfidPage: false,
    ProfilePage: false,
    AuthModal: false,
    AuthStatus: false,
    authChecked: false,
    GeoFound: false,
    UserGeoposition: null,
    Stations: {},
    SessionModalData: null,
    AccountInfo: null,
    MobileMenuStatus: false,
    isMobile: false,
    legend: false,
    socketData: null,
    socketFinishedData: null,
    reconnectSocket: false,
    rfidStatus: 0,
    RealtimeUserPosition: false,
    isProd: false,
    mapReady: false,
    DCmode: false,
})
