export default ({
    getRfidStatus: state => {
        return state.rfidStatus
    },
    getReconnectSocket: state => {
        return state.reconnectSocket
    },
    getSocketFinishedData: state => {
        return state.socketFinishedData
    },
    getSocketData: state => {
        return state.socketData
    },
    getLegend: state => {
        return state.legend
    },
    getIsMobile: state => {
        return state.isMobile
    },
    getIsProd: state => {
        return state.isProd
    },
    getMobileMenuStatus: state => {
        return state.MobileMenuStatus
    },
    getFavoritesPage: state => {
        return state.FavoritesPage
    },
    getMainPage: state => {
        return state.MainPage
    },
    getListPage: state => {
        return state.ListPage
    },
    getBalancePage: state => {
        return state.BalancePage
    },
    getRfidPage: state => {
        return state.RfidPage
    },
    getHistoryPage: state => {
        return state.HistoryPage
    },
    getProfilePage: state => {
        return state.ProfilePage
    },
    getUserGeoposition: state => {
        return state.UserGeoposition
    },
    getRealtimeUserPosition: state => {
        return state.RealtimeUserPosition
    },
    getSessionModalData: state => {
        return state.SessionModalData
    },
    getGeoFound: state => {
        return state.GeoFound
    },
    getStations: state => {
        return state.Stations
    },
    getAccountInfo: state => {
        if (state.AccountInfo !== null) {
            return state.AccountInfo
        } else {
            return false
        }
    },
    getAuthStatus: state => {
        return state.AuthStatus
    },
    getMapReadyStatus: state => {
        return state.mapReady
    },
    getAuthChecked: state => {
        return state.authChecked
    },
    getDCmode: state => {
        return state.DCmode
    }
})
