<template>
  <div class="profile_page">
    <div class="profile_page_inner">
      <div class="custom_container">
        <div class="my_profile_block">
          <h3>{{ $t("my_profile_page.page_title") }}
            <div class="close_page">
              <a @click.prevent="MainPageActive" href="">
                <svg height="15px" viewBox="0 0 311 311.07733" width="15px"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill="#fff"
                        d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"/>
                  <path fill="#fff"
                        d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"/>
                </svg>
              </a>
            </div>
          </h3>
          <form class="update_phone" @submit.prevent="updateVatId">
            <label>VAT-ID
              <input maxlength="100" v-model="ProfileVatId" @change="ProfileVatIdChange"
                     @input="ProfileVatIdChange" type="text">
            </label>
            <div class="submit_block">
              <button type="submit">
                {{ $t("my_profile_page.confirm_btn") }}
              </button>
              <span class="statusMessage">{{ UpdatePhoneStatusMessage }}</span>
            </div>
          </form>
          <!-- <div class="email_block">
            <div class="block_title">
              Email
            </div>
            <div class="email">
              {{ ProfileEmail }}
            </div>
          </div> -->
          <form class="update_phone" @submit.prevent="updatePhone">
            <label>{{ $t("my_profile_page.phone") }}
              <input minlength="10" maxlength="25" v-model="ProfilePhone" @change="ProfilePhoneChange"
                     @input="ProfilePhoneChange" disabled="disabled" type="tel">
            </label>
            <div class="submit_block">
<!--              <button type="submit" :disabled="UpdatePhoneDataValidated === false">-->
              <button type="submit" disabled="disabled">
                {{ $t("my_profile_page.confirm_btn") }}
              </button>
              <span class="statusMessage">{{ UpdatePhoneStatusMessage }}</span>
            </div>
          </form>
          <div class="my_profile_block_inner">
            <form class="update_info" @submit.prevent="updateProfile">
              <label>{{ $t("my_profile_page.name") }}
                <input minlength="2" maxlength="20" v-model="ProfileName" @change="ProfileNameChange"
                       @input="ProfileNameChange" type="text">
              </label>
              <label>{{ $t("my_profile_page.surname") }}
                <input minlength="2" maxlength="25" v-model="ProfileSurname" @change="ProfileSurnameChange"
                       @input="ProfileSurnameChange" type="text">
              </label>
              <div class="submit_block">
                <span class="statusMessage">{{ UpdateProfileStatusMessage }}</span>
                <button type="submit" :disabled="UpdateProfileDataValidated === false">
                  {{ $t("my_profile_page.save_btn") }}
                </button>
              </div>
            </form>
            <form class="update_password" @submit.prevent="updatePassword">
              <label>{{ $t("my_profile_page.new_password") }}
                <input :style="updatePasswordValidation" autocomplete="on" v-model="ProfilePassword" type="password">
              </label>
              <label>{{ $t("my_profile_page.confirm_new_password") }}
                <input :style="updateConfirmPasswordValidation" autocomplete="on" v-model="ProfileConfirmPassword"
                       type="password">
              </label>
              <div class="submit_block">
                <span class="statusMessage">{{ UpdatePasswordStatusMessage }}</span>
                <button type="submit" :disabled="!UpdatePasswordValidated">
                  {{ $t("my_profile_page.save_btn") }}
                </button>
              </div>
            </form>
            <div style="display: none;" class="close_page_bottom" v-if="getIsMobile">
              <div class="close_page_bottom_inner" @click.prevent="MainPageActive">
                <svg height="15px" viewBox="0 0 311 311.07733" width="15px"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill="#fff"
                        d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"/>
                  <path fill="#fff"
                        d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"/>
                </svg>
              </div>
            </div>
            <div v-if="!getIsMobile" class="logout_block">
              <button :class="{opened : LogoutPopup}" @click="LogoutPopup = true" class="logout_btn">
                {{ $t("my_profile_page.logout_btn") }}
              </button>
              <div :class="{active : LogoutPopup}" class="confirm_block">
                <button @click="LogoutUser" class="yep">{{ $t("my_profile_page.logout_popup_yes_btn") }}</button>
                <button @click="LogoutPopup = false" class="nope">{{
                    $t("my_profile_page.logout_popup_no_btn")
                  }}
                </button>
              </div>
            </div>
            <div style="opacity: 0; pointer-events: none;" class="lang_block">
              <div class="lang_block_inner">
                <span class="lang_block_title">{{ $t('switch_lang') }}</span>
                <span class="lang_block_item" :class="{active : $i18n.locale === lang.code}" @click="setLang(lang.code)"
                      v-for="lang in langs" :key="lang.code">{{
                    lang.name
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import axios from 'axios'

export default {
  name: "ProfilePage",
  data() {
    return {
      langs:
          [
            {code: 'bg', name: 'Български'},
            {code: 'en', name: 'English'},
            {code: 'kz', name: 'Qazaq'},
            {code: 'ru', name: 'Русский'}
          ],
      LogoutPopup: false,
      UpdatePhoneDataValidated: false,
      UpdateVatIdDataValidated: false,
      UpdatePhoneStatusMessage: '',
      UpdateVatIdStatusMessage: '',
      UpdateProfileDataValidated: false,
      UpdateProfileStatusMessage: '',
      UpdatePasswordValidated: false,
      UpdatePasswordStatusMessage: '',
      ProfileVatId: this.$store.getters.getAccountInfo.VatId,
      ProfilePhone: this.$store.getters.getAccountInfo.Phone,
      ProfileEmail: this.$store.getters.getAccountInfo.Email,
      ProfileName: this.$store.getters.getAccountInfo.Firstname,
      ProfileSurname: this.$store.getters.getAccountInfo.Surname,
      ProfilePassword: '',
      ProfileConfirmPassword: '',
    }
  },
  computed: {
    ...mapGetters(['getAccountInfo', 'getIsMobile']),
    updatePasswordValidation() {
      let _this = this
      if (this.ProfilePassword.length === 0) {
        _this.UpdatePasswordValidated = false
        return "border-color: #000"
      }
      if (this.ProfilePassword.length > 0 && this.ProfilePassword.length < 8 || this.ProfilePassword !== this.ProfileConfirmPassword) {
        _this.UpdatePasswordValidated = false
        return "border-color: red"
      }
      _this.UpdatePasswordValidated = true
      return "border-color: #62A321"
    },
    updateConfirmPasswordValidation() {
      let _this = this
      if (this.ProfileConfirmPassword.length === 0) {
        _this.UpdatePasswordValidated = false
        return "border-color: #000"
      }
      if (this.ProfileConfirmPassword.length > 0 && this.ProfileConfirmPassword.length < 8 || this.ProfilePassword !== this.ProfileConfirmPassword) {
        _this.UpdatePasswordValidated = false
        return "border-color: red"
      }
      _this.UpdatePasswordValidated = true
      return "border-color: #62A321"
    },
  },
  methods: {
    setLang(lang) {
      let _this = this
      if (this.$i18n.locale !== lang) {
        localStorage.setItem('SessionLang', lang)
        this.$i18n.locale = lang
        let updateProfileLangUrl = process.env.VUE_APP_API_URL + '/app/besp/account/update_profile/?format=json&f_token=' + localStorage.getItem('AuthToken') + '&lang=' + lang
        axios({
          url: 'https://voltspot.net/url/cors.php',
          method: 'POST',
          params: {
            url: updateProfileLangUrl
          }
        })
            .then(resp => {
              if (resp.data.rcode >= 0) {
                _this.$store.dispatch('gotAccountInfo')
                _this.$store.dispatch('gotStations')
              }
            })
      }
    },
    updatePhone() {
      let _this = this
      let arrayData = []
      if (this.ProfilePhone !== '') {
        arrayData.push('&f_phone=' + this.ProfilePhone)
      }
      let data = arrayData.join('')
      let updatePhoneUrl = process.env.VUE_APP_API_URL + '/app/besp/account/update_profile/?format=json&f_token=' + localStorage.getItem('AuthToken') + data
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: updatePhoneUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.$store.dispatch('gotAccountInfo')
              _this.UpdatePhoneDataValidated = false
              let cookLang = localStorage.getItem('SessionLang')
              if (cookLang === 'ru') {
                _this.UpdatePhoneStatusMessage = 'Профиль обновлен'
              } else if (cookLang === 'en') {
                _this.UpdatePhoneStatusMessage = 'Profile updated'
              } else if (cookLang === 'kz') {
                _this.UpdatePhoneStatusMessage = 'Profıl jańartyldy'
              } else {
                _this.UpdatePhoneStatusMessage = 'Профилът е актуализиран'
              }
              setTimeout(() => _this.UpdatePhoneStatusMessage = '', 2000)
            }
          })
          .catch(
              _this.Spinner = false
          )
    },
    updateVatId() {
      let _this = this
      let arrayData = []
      if (this.ProfileVatId !== '') {
        arrayData.push('&f_vatid=' + this.ProfileVatId)
      }
      let data = arrayData.join('')
      let updateVatIdUrl = process.env.VUE_APP_API_URL + '/app/besp/account/update_profile/?format=json&f_token=' + localStorage.getItem('AuthToken') + data
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: updateVatIdUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.$store.dispatch('gotAccountInfo')
              _this.UpdateVatIdDataValidated = false
              let cookLang = localStorage.getItem('SessionLang')
              if (cookLang === 'ru') {
                _this.UpdateVatIdStatusMessage = 'VAT-ID обновлен'
              } else if (cookLang === 'en') {
                _this.UpdateVatIdStatusMessage = 'VAT-ID updated'
              } else if (cookLang === 'kz') {
                _this.UpdateVatIdStatusMessage = 'VAT-ID jańartyldy'
              } else {
                _this.UpdateVatIdStatusMessage = 'VAT-ID е актуализиран'
              }
              setTimeout(() => _this.UpdateVatIdStatusMessage = '', 2000)
            }
          })
          .catch(
              _this.Spinner = false
          )
    },
    updateProfile() {
      let _this = this
      let arrayData = []
      if (this.ProfileName !== '') {
        arrayData.push('&f_firstname=' + this.ProfileName)
      }
      if (this.ProfileSurname !== '') {
        arrayData.push('&f_surname=' + this.ProfileSurname)
      }
      let data = arrayData.join('')
      let updateProfileUrl = process.env.VUE_APP_API_URL + '/app/besp/account/update_profile/?format=json&f_token=' + localStorage.getItem('AuthToken') + data
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: updateProfileUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.$store.dispatch('gotAccountInfo')
              _this.UpdateProfileDataValidated = false
              let cookLang = localStorage.getItem('SessionLang')
              if (cookLang === 'ru') {
                _this.UpdateProfileStatusMessage = 'Профиль обновлен'
              } else if (cookLang === 'en') {
                _this.UpdateProfileStatusMessage = 'Profile updated'
              } else if (cookLang === 'kz') {
                _this.UpdateProfileStatusMessage = 'Profıl jańartyldy'
              } else {
                _this.UpdateProfileStatusMessage = 'Профилът е актуализиран'
              }
              setTimeout(() => _this.UpdateProfileStatusMessage = '', 2000)
            }
          })
          .catch(
              _this.Spinner = false
          )
    },
    updatePassword() {
      let _this = this
      let data
      if (_this.ProfilePassword !== '') {
        data = _this.ProfilePassword
      }
      let updatePasswordUrl = process.env.VUE_APP_API_URL + '/app/besp/account/update_profile/?format=json&f_token=' + localStorage.getItem('AuthToken') + '&f_password=' + encodeURIComponent(data)
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: updatePasswordUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.$store.dispatch('gotAccountInfo')
              _this.UpdatePasswordValidated = false
              let cookLang = localStorage.getItem('SessionLang')
              if (cookLang === 'ru') {
                _this.UpdatePasswordStatusMessage = 'Пароль обновлен'
              } else if (cookLang === 'en') {
                _this.UpdatePasswordStatusMessage = 'Password updated'
              } else if (cookLang === 'kz') {
                _this.UpdatePasswordStatusMessage = 'Paról jańartyldy'
              } else {
                _this.UpdatePasswordStatusMessage = 'Паролата е актуализирана'
              }
              setTimeout(() => _this.UpdatePasswordStatusMessage = '', 2000)
              _this.ProfilePassword = ''
              _this.ProfileConfirmPassword = ''
            }
          })
          .catch(
              _this.UpdatePasswordValidated = false,
              _this.ProfilePassword = '',
              _this.ProfileConfirmPassword = ''
          )
    },
    MainPageActive() {
      this.$store.commit('setMainPage')
    },
    ProfilePhoneChange(e) {
      let _this = this
      this.ProfilePhone = e.target.value
      _this.UpdatePhoneDataValidated = _this.ProfilePhone !== _this.$store.getters.getAccountInfo.Phone && _this.ProfilePhone.length !== 0
    },
    ProfileVatIdChange(e) {
      let _this = this
      this.ProfileVatId = e.target.value
      _this.UpdateVatIdDataValidated = _this.ProfileVatId !== _this.$store.getters.getAccountInfo.VatId && _this.ProfileVatId.length !== 0
    },
    ProfileNameChange(e) {
      let _this = this
      this.ProfileName = e.target.value
      _this.UpdateProfileDataValidated = _this.ProfileName !== _this.$store.getters.getAccountInfo.Firstname && _this.ProfileName.length !== 0 && _this.ProfileSurname.length !== 0
    },
    ProfileSurnameChange(e) {
      let _this = this
      this.ProfileSurname = e.target.value
      _this.UpdateProfileDataValidated = this.ProfileSurname !== this.$store.getters.getAccountInfo.Surname && _this.ProfileName.length !== 0 && _this.ProfileSurname.length !== 0
    },
    LogoutUser() {
      localStorage.removeItem('AuthToken')
      this.$store.commit('setMainPage')
      this.$store.state.AuthStatus = false
      this.$store.dispatch('gotAccountInfo')
      window.location.reload()
    }
  },
  created() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  },
  mounted() {
    let browser = window.navigator.userAgent.substr(-40,)
    let firefox = browser.indexOf('Firefox')
    let mi = browser.indexOf('MiuiBrowser')
    if (firefox >= 0 || mi >= 0) {
      document.querySelector('.profile_page_inner').style.background = 'rgba(0, 0, 0, 0.8)'
    }
  }
}
</script>

<style scoped lang="scss">

.profile_page {
  .profile_page_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin-top: 40px;
    padding-top: 80px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
    z-index: 402;

    .my_profile_block {
      h3 {
        color: #fff;
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1;
        display: block;
        margin: 0 0 30px 0;
        position: relative;

        .close_page {
          position: absolute;
          top: 0;
          right: 0;

          a {
            background: transparent;
            font-weight: 400;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            border: 1px solid #fff;
            border-radius: 5px;
            text-decoration: none;
            outline: none;
            box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.4);
            transition: all .2s linear;

            &:hover {
              transition: all .2s linear;
              box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.8);
              background: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }

      .email_block {
        margin-bottom: 20px;

        .block_title {
          color: #fff;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1;
          margin-right: 65px;
        }

        .email {
          margin-top: 5px;
          border: 1px solid #000;
          color: #fff;
          padding: 5px 15px;
          font-size: 1rem;
          cursor: default;
          width: fit-content;
          font-weight: 400;
          background: rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
          border-radius: 5px;
        }
      }

      form.update_phone {
        display: flex;
        margin-bottom: 30px;

        label {
          color: #fff;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1;
          margin-right: 65px;

          input {
            display: block;
            margin-top: 5px;
            outline: none;
            border: 1px solid #000;
            color: #fff;
            padding: 5px 15px;
            font-size: 1rem;
            font-weight: 400;
            background: rgba(0, 0, 0, 0.5);
            transition: all .2s linear;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
            border-radius: 5px;
          }
        }

        .submit_block {
          display: flex;
          margin-top: 15px;

          .statusMessage {
            margin: 13px 0 0 50px;
            height: 16px;
            line-height: 1;
            color: #62A321;
          }

          button {
            margin-top: 5px;
            background: transparent;
            border-radius: 5px;
            color: #fff;
            border: 1px solid #62A321;
            font-size: 1rem;
            font-weight: 400;
            height: 31px;
            width: 185px;
            padding: 0 15px;
            outline: none;
            line-height: 1;
            transition: all .2s linear;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

            &:disabled {
              background: #80808087;
              color: #fff;
              border-color: #fff;
            }

            &:not([disabled]):hover {
              transition: all .2s linear;
              color: #fff;
              background: #62A321;
              border: 1px solid #fff;
            }
          }
        }
      }

      .my_profile_block_inner {
        display: flex;
        justify-content: space-between;

        form.update_info {
          display: flex;
          flex-direction: column;

          label {
            color: #fff;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 10px;

            input {
              display: block;
              margin-top: 5px;
              outline: none;
              border: 1px solid #000;
              color: #fff;
              padding: 5px 15px;
              font-size: 1rem;
              font-weight: 400;
              background: rgba(0, 0, 0, 0.5);
              transition: all .2s linear;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
            }

            &:first-child input {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }

          .submit_block {
            display: flex;
            flex-direction: column;

            .statusMessage {
              margin: 0;
              height: 16px;
              line-height: 1;
              color: #62A321;
            }

            button {
              margin-top: 5px;
              background: transparent;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              border: 1px solid #62A321;
              font-size: 1rem;
              font-weight: 400;
              height: 31px;
              width: 100%;
              padding: 0 15px;
              outline: none;
              line-height: 1;
              transition: all .2s linear;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

              &:disabled {
                background: #80808087;
                color: #fff;
                border-color: #fff;
              }

              &:not([disabled]):hover {
                transition: all .2s linear;
                color: #fff;
                background: #62A321;
                border: 1px solid #fff;
              }
            }
          }
        }

        form.update_password {
          display: flex;
          flex-direction: column;

          label {
            color: #fff;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 10px;

            input {
              display: block;
              margin-top: 5px;
              outline: none;
              border: 1px solid #000;
              color: #fff;
              padding: 5px 15px;
              font-size: 1rem;
              font-weight: 400;
              background: rgba(0, 0, 0, 0.5);
              transition: all .2s linear;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
            }

            &:first-child input {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }

          .submit_block {
            display: flex;
            flex-direction: column;

            .statusMessage {
              margin: 0;
              height: 16px;
              line-height: 1;
              color: #62A321;
            }

            button {
              margin-top: 5px;
              background: transparent;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              border: 1px solid #62A321;
              font-size: 1rem;
              font-weight: 400;
              height: 31px;
              width: 100%;
              padding: 0 15px;
              outline: none;
              line-height: 1;
              transition: all .2s linear;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

              &:disabled {
                background: #80808087;
                color: #fff;
                border-color: #fff;
              }

              &:not([disabled]):hover {
                transition: all .2s linear;
                color: #fff;
                background: #62A321;
                border: 1px solid #fff;
              }
            }
          }
        }

        .logout_block {
          position: relative;
          overflow: hidden;
          min-width: 155px;

          button.logout_btn {
            position: relative;
            margin-top: 20px;
            background: transparent;
            color: #fff;
            border: 1px solid #fff;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            font-size: 1rem;
            font-weight: 400;
            width: 100%;
            padding: 0 10px;
            outline: none;
            height: 31px;
            line-height: 1;
            transition: all .2s linear;
            z-index: 1;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

            &.opened {
              transition: all .2s linear;
              background: rgba(0, 0, 0, 0.5);
            }
          }

          .confirm_block {
            display: flex;
            height: 31px;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            top: 20px;
            opacity: 0;
            transition: all .1s linear;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

            &.active {
              transition: all .1s linear;
              top: 51px;
              opacity: 1;
            }

            .nope {
              width: 50%;
              border: none;
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              border-bottom-right-radius: 5px;
              background: transparent;
              color: #fff;
              outline: none;
              transition: all .2s linear;

              &:hover {
                transition: all .2s linear;
                background: red;
              }
            }

            .yep {
              width: 50%;
              border: none;
              border-bottom: 1px solid #fff;
              border-left: 1px solid #fff;
              border-right: 1px solid #fff;
              border-bottom-left-radius: 5px;
              background: transparent;
              color: #fff;
              outline: none;
              transition: all .2s linear;

              &:hover {
                transition: all .2s linear;
                background: #62A321;
              }
            }
          }
        }

        .close_page_bottom {
          position: relative;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 20px;

          .close_page_bottom_inner {
            background: transparent;
            font-weight: 400;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            border: 1px solid #fff;
            border-radius: 5px;
            text-decoration: none;
            outline: none;
            box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.4);
            transition: all .2s linear;

            &:hover {
              transition: all .2s linear;
              box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.8);
              background: rgba(0, 0, 0, 0.2);
            }
          }
        }

        .lang_block {
          padding-top: 20px;
          min-width: 200px;
          display: flex;

          .lang_block_inner {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            user-select: none;
            justify-content: space-between;
            align-items: center;

            .lang_block_title {
              border: 1px solid #fff;
              border-radius: 5px 5px 0 0;
              color: #fff;
              width: 100%;
              height: 31px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .lang_block_item {
              padding: 0 15px;
              display: flex;
              align-items: center;
              width: 100%;
              border: 1px solid #fff;
              background: rgba(0, 0, 0, 0.5);
              color: #fff;
              cursor: pointer;
              transition: all .2s linear;

              &:last-child {
                border-radius: 0 0 5px 5px;
              }

              &.active {
                color: #62a321;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}

//MEDIA STYLES
@media screen and (max-width: 991px) {
  .profile_page {
    .profile_page_inner {
      padding-top: 40px;
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 767px) {
  .profile_page {
    .profile_page_inner {
      .my_profile_block {
        .email_block {
          display: flex;
          flex-direction: column;
          border: 1px solid #fff;
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.8);
          margin-bottom: 15px;

          .email {
            width: 100%;
          }
        }
        form.update_phone {
          flex-direction: column;
          border: 1px solid #fff;
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          box-shadow: 0 1px 6px 0 rgb(255 255 255 / 80%);
          margin-bottom: 15px;

          .submit_block {
            position: relative;
            button {
              width: 100%;
              border-radius: 0 0 5px 5px;
              margin-left: 0;
            }

            span {
              position: absolute;
              bottom: 38px;
              margin: 0 auto!important;
            }
          }

          label {
            margin-right: 0;
            input {
              width: 100%;
              border-radius: 5px 5px 0 0;
            }
          }
        }
        .my_profile_block_inner {
          flex-wrap: wrap;

          form.update_info {
            margin: 0 15px 15px 0;
            padding: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .profile_page {
    .profile_page_inner {
      .my_profile_block {
        h3 {
          font-size: 1.6rem;
          padding-right: 35px;

          .close_page {
            top: -5px;
          }
        }

        .my_profile_block_inner {
          form.update_info {
            border: 1px solid #fff;
            padding: 10px;
            border-radius: 5px;
            margin-right: 0;
            width: 100%;
            box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.8);

            input {
              width: 100%;
            }

            button {
              width: 100%;
            }
          }

          form.update_password {
            border: 1px solid #fff;
            padding: 15px;
            border-radius: 5px;
            margin-right: 0;
            width: 100%;
            box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.8);

            input {
              width: 100%;
            }

            button {
              width: 100%;
            }
          }

          .lang_block {
            padding-bottom: 80px;
            width: 100%;

            .lang_block_inner {
              box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.8);
              border-radius: 5px;
              .lang_block_item {
                height: 31px;
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
