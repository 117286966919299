import Vue from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/scss/custom.scss'
import Vuex from 'vuex'
import {store} from './store/store'
import {LMap, LMarker} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import axios from 'axios'
import VueMask from 'v-mask'
import VueI18n from 'vue-i18n'
import Vue2TouchEvents from 'vue2-touch-events'
import L from 'leaflet';
import Notifications from 'vue-notification'
import moment from 'moment'

delete L.Icon.Default.prototype._getIconUrl;
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.prototype.$http = axios
Vue.prototype.moment = moment

Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer')
Vue.component('l-marker', LMarker)

Vue.config.productionTip = false

Vue.use(Vue2TouchEvents, {
  tapTolerance: 1,
  disableClick: true
})
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VueMask)
Vue.use(Notifications)
Vue.use(require('vue-moment'));

import { languages } from './translations/config_local'
import { defaultLocale } from './translations/config_local'

const messages = Object.assign(languages)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
});

new Vue({
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
