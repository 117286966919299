<template>
  <div class="rfid_page">
    <div class="rfid_page_inner">
      <div class="custom_container">
        <div class="operations_rfid_outer">
          <div class="block_title">
            <h3>RFID
              <div class="close_page">
                <a @click.prevent="MainPageActive" href="">
                  <svg height="15px" viewBox="0 0 311 311.07733" width="15px"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff"
                          d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"/>
                    <path fill="#fff"
                          d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"/>
                  </svg>
                </a>
              </div>
            </h3>
          </div>
          <div class="infobar">
            {{ $t("rfid_title") }}
          </div>

          <transition name="slide-left" v-if="getRfidStatus == 1">
            <div class="rfid_active_bar">
              <div id="rfid_bar_text" class="rfid_active_bar_inner">
                {{ $t("rfid_subtitle") }}
              </div>
              <div class="timer">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="rotate(0 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(30 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(60 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(90 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(120 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(150 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(180 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(210 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(240 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(270 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(300 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(330 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                  </rect>
                </g>
                  <!-- [ldio] generated by https://loading.io/ --></svg>
              </div>
            </div>
          </transition>

          <transition name="slide-left" v-if="getRfidStatus == 2">
            <div class="rfid_active_bar">
              <div id="rfid_bar_text" class="rfid_active_bar_inner">
                {{ $t("rfid_set_card") }}
              </div>
              <div class="timer">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="rotate(0 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(30 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(60 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(90 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(120 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(150 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(180 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(210 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(240 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(270 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(300 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                  </rect>
                </g><g transform="rotate(330 50 50)">
                  <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#62a321">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                  </rect>
                </g>
                  <!-- [ldio] generated by https://loading.io/ --></svg>
              </div>
            </div>
          </transition>

          <transition v-if="getRfidStatus == 0 && loading === false">
            <div class="page_content">
            <div class="rfid_cards">
              <div v-for="(item, index) in rfidList.slice(0, 2)" :key="index" class="rfid_card">
                <div class="rfid_card_inner">
                  <img src="/images/rfid.png" alt="">
                  <span @click="editRfid(item)" class="rfid_name">{{ item.Title }}
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style="margin-left: 5px; fill:#ffffff;">
                    <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path>
                  </svg>
                </span>
                </div>
                <div class="card_events">
                  <div class="card_status">
                    <span @click="powerRfid(item)" v-if="item.Enabled == 0" class="rfid_status">{{ $t("rfid_on") }}</span>
                    <span @click="powerRfid(item)" v-else>{{ $t("rfid_off") }}</span>
                  </div>
                  <span @click="removeRfid(item)" class="card_remove">
                    {{ $t("rfid_rm") }}
                  </span>
                </div>
              </div>

              <div @click="addRfid()" v-if="Object.keys(rfidList).length < 9999999" class="add_card">
                <span>+</span>
              </div>

            </div>
          </div>
          </transition>
          <img v-if="loading === true" style="display: flex; margin: 0 auto" src="/images/new_spinner.gif" alt="" class="spinner">
        </div>
      </div>
      <transition name="fade">
        <div v-if="infoPopup" id="info_modal" class="info_modal">
          <div class="info_modal_inner">
            <a @click.prevent="closeInfoPopup()">
              <svg height="15px" viewBox="0 0 311 311.07733" width="15px" xmlns="http://www.w3.org/2000/svg">
                <path fill="#fff"
                  d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
                <path fill="#fff"
                  d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
              </svg>
            </a>
            <div class="info">
              {{ infoPopupText }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex";
import $t from "i18n";

export default {
  name: "RfidPage",
  data() {
    return {
      rfidList: [],
      loading: false,
      infoPopup: false,
      infoPopupText: false
    }
  },
  mounted() {
    let _this = this
    let browser = window.navigator.userAgent.substr(-40,)
    let firefox = browser.indexOf('Firefox')
    let mi = browser.indexOf('MiuiBrowser')
    if (firefox >= 0 || mi >= 0) {
      document.querySelector('.rfid_page_inner').style.background = 'rgba(0, 0, 0, 0.8)'
    }

    this.loading = true
    let rfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_list/?format=json&f_token=' + localStorage.getItem('AuthToken')

    axios({
      url: 'https://voltspot.net/url/cors.php',
      method: 'POST',
      params: {
        url: rfidUrl
      }
    })
        .then(resp => {
          if (resp.data.rcode >= 0) {
            let parse = Object.values(JSON.parse(JSON.stringify(resp.data.rdata.Records)))
            this.rfidList = parse
          } else {
            console.log('Ошибка получения списка RFID: ' + resp.data.rtext)
          }
          _this.loading = false
        })
  },
  watch: {
    getRfidStatus(status) {
      let _this = this
      if (status == '2') {
        // Vue.notify({
        //   type: 'success',
        //   text: 'Карта успешно привязана'
        // })

        _this.openInfoPopup($t('rfid_added'));
        this.$store.commit('setRfidStatus', 0)
        this.loading = true
        let rfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_list/?format=json&f_token=' + localStorage.getItem('AuthToken')

        axios({
          url: 'https://voltspot.net/url/cors.php',
          method: 'POST',
          params: {
            url: rfidUrl
          }
        })
            .then(resp => {
              if (resp.data.rcode >= 0) {
                let parse = Object.values(JSON.parse(JSON.stringify(resp.data.rdata.Records)))
                this.rfidList = parse
              } else {
                console.log('Ошибка получения списка RFID: ' + resp.data.rtext)
              }
              _this.loading = false
            })
      }
    }
  },
  methods: {
    openInfoPopup(msg) {
      this.infoPopupText = msg;
      this.infoPopup = true;
    },
    closeInfoPopup() {
      this.infoPopupText = '';
      this.infoPopup = false;
    },
    addRfid() {
      if (this.$store.getters.getSocketData == null) {
        // this.$notify({
        //   type: 'info',
        //   text: 'Добавление карты возможно только во время зарядки электромобиля'
        // });
        this.openInfoPopup(`${this.$t("rfid_possible_while_charging")}`);
        return;
      }
      else if (this.$store.getters.getSocketData != null && this.$store.getters.getSocketData.Data.Nfc == 2) {
        // this.$notify({
        //   type: 'info',
        //   text: 'На данной станции не возможно добавить карту, так как на ней не работает NFC считыватель'
        // });
        this.openInfoPopup(`${this.$t("rfid_is_not_possible")}`);
        return;
      }
       else if (this.$store.getters.getSocketData != null && this.$store.getters.getSocketData.Data.Nfc == 1) {
        let url = process.env.VUE_APP_API_URL + '/app/besp/session/nfc/?format=json&f_token=' + localStorage.getItem('AuthToken');
        axios({
          url: 'https://voltspot.net/url/cors.php',
          method: 'POST',
          params: {
            url: url
          }
        })
        this.$store.commit('setRfidStatus', 1)
      }
       else {
        // this.$notify({
        //   type: 'info',
        //   text: 'Дождитесь завершения предыдущей операции'
        // });
        this.openInfoPopup(`${this.$t("rfid_wait_for_the_previous_operation")}`);
        return;
      }
    },
    MainPageActive() {
      this.$store.commit('setRfidStatus', 0)
      this.$store.commit('setMainPage')
    },
    powerRfid(item) {
      let _this = this
      let rfid,
          powerOn;
      if (item.Enabled == 1) {
        let current_lang = localStorage.getItem('SessionLang')
        if(current_lang === 'ru') {
          rfid = confirm("Выключить " + item.Title + "?");
        } else if (current_lang === 'en') {
          rfid = confirm("Disable " + item.Title + "?");
        } else if(current_lang === 'kz') {
          rfid = confirm(item.Title +" öşırılsın be?");
        } else {
          rfid = confirm("Деактивиране на " + item.Title + "?");
        }
        powerOn = 0;
      } else {
        let current_lang = localStorage.getItem('SessionLang')
        if(current_lang === 'ru') {
          rfid = confirm("Включить " + item.Title + "?");
        } else if (current_lang === 'en') {
          rfid = confirm("Enable " + item.Title + "?");
        } else if(current_lang === 'kz') {
          rfid = confirm(item.Title +" qosylsyn ba?");
        } else {
          rfid = confirm("Активиране на " + item.Title + "?");
        }
        powerOn = 1;
      }

      if (!rfid) return

      let editRfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_update/?f_id=' + item.Id + '&f_enabled=' + powerOn + '&format=json&f_token=' + localStorage.getItem('AuthToken')
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: editRfidUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              this.loading = true
              let rfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_list/?format=json&f_token=' + localStorage.getItem('AuthToken')

              axios({
                url: 'https://voltspot.net/url/cors.php',
                method: 'POST',
                params: {
                  url: rfidUrl
                }
              })
                  .then(resp => {
                    if (resp.data.rcode >= 0) {
                      // this.$notify({
                      //   type: 'success',
                      //   text: 'Статус RFID обновлен'
                      // });
                      _this.openInfoPopup(`${this.$t("rfid_status_updated")}`);
                      let parse = Object.values(JSON.parse(JSON.stringify(resp.data.rdata.Records)))
                      this.rfidList = parse
                    } else {
                      console.log('Ошибка получения списка RFID: ' + resp.data.rtext)
                    }
                    _this.loading = false
                  })
            } else {
              // this.$notify({
              //   type: 'error',
              //   text: 'Ошибка обновления RFID'
              // });
              _this.openInfoPopup(`${this.$t("rfid_status_update_failed")}`);
            }
            _this.loading = false
          })
    },
    editRfid(item) {
      let _this = this
      let current_lang = localStorage.getItem('SessionLang')
      let newRfidName;
        if(current_lang === 'ru') {
          newRfidName = prompt("Введите название RFID-карты: ");
        } else if (current_lang === 'en') {
          newRfidName = prompt("Enter the name of the RFID card: ");
        } else if(current_lang === 'kz') {
          newRfidName = prompt("RFID kartasynyñ atyn engızıñız: ");
        } else {
          newRfidName = prompt("Въведете името на RFID картата: ");
        }

      if (newRfidName != null && newRfidName.trim().length < 4) {
        // this.$notify({
        //   type: 'error',
        //   text: 'Слишком короткое название'
        // });
        _this.openInfoPopup(`${this.$t("rfid_name_short")}`);
        return
      }
      if (!newRfidName) {
        return
      }
      if (newRfidName && newRfidName.trim().length > 45) {
        // this.$notify({
        //   type: 'error',
        //   text: 'Слишком длинное название'
        // });
        _this.openInfoPopup(`${this.$t("rfid_name_long")}`);
        return
      }
      let editRfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_update/?f_id=' + item.Id + '&f_title=' + newRfidName.trim() + '&format=json&f_token=' + localStorage.getItem('AuthToken')
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: editRfidUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              this.loading = true
              let rfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_list/?format=json&f_token=' + localStorage.getItem('AuthToken')

              axios({
                url: 'https://voltspot.net/url/cors.php',
                method: 'POST',
                params: {
                  url: rfidUrl
                }
              })
                  .then(resp => {
                    if (resp.data.rcode >= 0) {
                      // this.$notify({
                      //   type: 'success',
                      //   text: 'Название RFID обновлено'
                      // });
                      _this.openInfoPopup(`${this.$t("rfid_name_updated")}`);
                      let parse = Object.values(JSON.parse(JSON.stringify(resp.data.rdata.Records)))
                      this.rfidList = parse
                    } else {
                      console.log('Ошибка получения списка RFID: ' + resp.data.rtext)
                    }
                    _this.loading = false
                  })
            } else {
              // this.$notify({
              //   type: 'error',
              //   text: 'Ошибка обновления RFID'
              // });
              _this.openInfoPopup(`${this.$t("rfid_status_update_failed")}`);
            }
            _this.loading = false
          })
    },
    removeRfid(item) {
      let _this = this
      let removeRfid;
      let current_lang = localStorage.getItem('SessionLang')
        if(current_lang === 'ru') {
          removeRfid = confirm("Удалить " + item.Title + "?");
        } else if (current_lang === 'en') {
          removeRfid = confirm("Delete " + item.Title + "?");
        } else if(current_lang === 'kz') {
          removeRfid = confirm(item.Title + " joiylsyn ba?");
        } else {
          removeRfid = confirm("Изтриване на " + item.Title + "?");
        }

      if (!removeRfid) return

      let removeRfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_delete/?format=json&f_id=' + item.Id + '&f_token=' + localStorage.getItem('AuthToken')
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: removeRfidUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              this.loading = true
              let rfidUrl = process.env.VUE_APP_API_URL + '/app/besp/account/nfc_list/?format=json&f_token=' + localStorage.getItem('AuthToken')

              axios({
                url: 'https://voltspot.net/url/cors.php',
                method: 'POST',
                params: {
                  url: rfidUrl
                }
              })
                  .then(resp => {
                    if (resp.data.rcode >= 0) {
                      // this.$notify({
                      //   type: 'success',
                      //   text: 'RFID удален из списка'
                      // });
                      _this.openInfoPopup(`${this.$t("rfid_removed")}`);
                      let parse = Object.values(JSON.parse(JSON.stringify(resp.data.rdata.Records)))
                      this.rfidList = parse
                    } else {
                      console.log('Ошибка получения списка RFID: ' + resp.data.rtext)
                    }
                    _this.loading = false
                  })
            } else {
              // this.$notify({
              //   type: 'error',
              //   text: 'Ошибка удаления RFID'
              // });
              _this.openInfoPopup(`${this.$t("rfid_remove_error")}`);
            }
            _this.loading = false
          })
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 575
    },
    ...mapGetters([
      'getRfidStatus'
    ]),
  },
  created() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }
}
</script>

<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-left-leave-active {
  transition: .2s;
}

.slide-left-enter-active {
  transition: .6s ease;
}

.slide-left-enter {
  transform: translate(100%, 0);
}

.slide-left-leave-to {
  transform: translate(100%, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: .2s;
}

.slide-right-enter {
  transform: translate(-100%, 0);
}

.slide-right-leave-to {
  transform: translate(-100%, 0);
}

.rfid_page {
  .rfid_active_bar {
    margin-bottom: 30px;
    border-radius: 5px;
    align-items: center;
    position: relative;
    width: 100%;
    display: flex;
    padding: 100px 10px;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.45);
    text-align: center;
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);

    .rfid_active_bar_inner {
      color: #fff;
      font-size: 20px;
    }

    .timer {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: 24px;
      margin-top: 30px;
    }
  }

  .rfid_page_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin-top: 40px;
    padding-top: 80px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
    z-index: 402;

    .info_modal {
      position: absolute;
      width: 100%;
      height: calc(100% + 100px);
      background: rgba(0, 0, 0, 0.2);
      z-index: 1000;
      backdrop-filter: blur(1px);
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: -100px;

      .info_modal_inner {
        display: flex;
        background: #fff;
        box-shadow: 0 1px 6px 0 rgb(32 33 36 / 30%) !important;
        padding: 40px 20px 20px 20px;
        border-radius: 5px;
        position: relative;
        max-width: 300px;
        width: 100%;

        a {
          position: absolute;
          top: 10px;
          right: 10px;
          line-height: 1;
          cursor: pointer;

          svg {
            path {
              fill: #000;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .block_title {
      h3 {
        color: #fff;
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1;
        display: block;
        margin: 0 0 30px 0;
        position: relative;

        .close_page {
          position: absolute;
          top: 0;
          right: 0;

          a {
            background: transparent;
            font-weight: 400;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            border: 1px solid #fff;
            border-radius: 5px;
            text-decoration: none;
            outline: none;
            box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.4);
            transition: all .2s linear;

            &:hover {
              transition: all .2s linear;
              box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.8);
              background: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .infobar {
      margin-bottom: 30px;
      border-radius: 5px;
      align-items: center;
      position: relative;
      width: 100%;
      display: flex;
      padding: 10px;
      background: rgba(0, 0, 0, 0.15);
      text-align: center;
      color: #fff;
      font-size: 14px;
      box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
    }

    .page_content {
      .rfid_cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
      }

      .rfid_card {
        .rfid_card_inner {
          position: relative;
          border-radius: 20px;
          height: 170px;
          padding-right: 100px;
          border: 2px solid #000;
          background: rgba(0, 0, 0, 0.3);
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          box-shadow: 0 1px 6px 0 rgb(32 33 36 / 68%);

          img {
            position: absolute;
            top: calc(50% - 32px);
            right: 20px;
          }

          span {
            color: #fff;
            cursor: pointer;
          }
        }

        .card_events {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;

          span {
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .add_card {
        position: relative;
        border-radius: 20px;
        height: 170px;
        border: 2px solid #000;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 6px 0 rgb(32 33 36 / 68%);
        cursor: pointer;

        span {
          color: #fff;
          font-size: 36px;
          font-weight: 400;
          border: 3px solid #fff;
          line-height: 1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 120px;
          width: 120px;
        }
      }
    }
  }
}

//MEDIA STYLES
@media screen and (max-width: 991px) {
  .rfid_page {
    .rfid_page_inner {
      padding-top: 40px;
      padding-bottom: 50px;
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 575px) {
  .rfid_page {
    .rfid_page_inner {

      .operations_rfid_outer {
        .block_title {
          h3 {
            font-size: 1.6rem;
            padding-right: 35px;

            .close_page {
              top: -5px;
            }
          }
        }

        .page_content .rfid_cards {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .rfid_page {
    .rfid_page_inner {
      .operations_rfid_outer {
        .operations_rfid_table {
          .table_mobile {
            margin-bottom: 20px;
            min-height: 200px;
            max-height: 300px;
            overflow: scroll;

            .table_mobile_inner {
              .item_row {
                span {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
