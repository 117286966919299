<template>
  <div @click="setLegend(false)" class="bottom_board animated">
    <div class="bottom_board_inner">
      <div class="change_view_block">
        <div v-if="getIsMobile" @click="getAuthStatus ? openMobileMenu() : setAuthModal(true)"
             class="mobile_nav_icon">
          <div class="hamburger_icon"></div>
          <p>{{ $t("menu") }}</p>
        </div>
        <span class="set_main_page" :class="{active:getMainPage}" @click="setMainPage">
           <!-- eslint-disable -->
            <svg version="1.1" height="21" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 48.625 48.625" style="enable-background:new 0 0 48.625 48.625;"
                 xml:space="preserve">
	<g>
		<polygon points="35.432,10.815 35.479,11.176 34.938,11.288 34.866,12.057 35.514,12.057 36.376,11.974 36.821,11.445
			36.348,11.261 36.089,10.963 35.7,10.333 35.514,9.442 34.783,9.591 34.578,9.905 34.578,10.259 34.93,10.5 		"/>
		<polygon
        points="34.809,11.111 34.848,10.629 34.419,10.444 33.819,10.583 33.374,11.297 33.374,11.76 33.893,11.76 		"/>
		<path d="M22.459,13.158l-0.132,0.34h-0.639v0.33h0.152c0,0,0.009,0.07,0.022,0.162l0.392-0.033l0.245-0.152l0.064-0.307
			l0.317-0.027l0.125-0.258l-0.291-0.06L22.459,13.158z"/>
		<polygon points="20.812,13.757 20.787,14.08 21.25,14.041 21.298,13.717 21.02,13.498 		"/>
		<path d="M48.619,24.061c-0.007-0.711-0.043-1.417-0.11-2.112c-0.225-2.317-0.779-4.538-1.609-6.62
			c-0.062-0.155-0.119-0.312-0.185-0.465c-1.106-2.613-2.659-4.992-4.56-7.045c-0.125-0.134-0.252-0.266-0.379-0.396
			c-0.359-0.373-0.728-0.737-1.11-1.086C36.344,2.402,30.604,0,24.312,0C17.967,0,12.186,2.445,7.852,6.44
			C6.842,7.371,5.914,8.387,5.072,9.475C1.896,13.583,0,18.729,0,24.312c0,13.407,10.907,24.313,24.313,24.313
			c9.43,0,17.617-5.4,21.647-13.268c0.862-1.682,1.533-3.475,1.985-5.354c0.115-0.477,0.214-0.956,0.3-1.441
			c0.245-1.381,0.379-2.801,0.379-4.25C48.625,24.228,48.62,24.145,48.619,24.061z M44.043,14.344l0.141-0.158
			c0.185,0.359,0.358,0.724,0.523,1.094l-0.23-0.009l-0.434,0.06V14.344z M40.53,10.102l0.004-1.086
			c0.382,0.405,0.75,0.822,1.102,1.254l-0.438,0.652l-1.531-0.014l-0.096-0.319L40.53,10.102z M11.202,7.403V7.362h0.487
			l0.042-0.167h0.797v0.348l-0.229,0.306h-1.098L11.202,7.403L11.202,7.403z M11.98,8.488c0,0,0.487-0.083,0.529-0.083
			s0,0.486,0,0.486L11.411,8.96l-0.209-0.25L11.98,8.488z M45.592,18.139h-1.779l-1.084-0.807l-1.141,0.111v0.696h-0.361
			l-0.39-0.278l-1.976-0.501v-1.28l-2.504,0.195l-0.776,0.417h-0.994L34.1,16.643l-1.207,0.67v1.261l-2.467,1.78l0.205,0.76h0.5
			L31,21.838l-0.352,0.129l-0.019,1.892l2.132,2.428h0.928l0.056-0.148h1.668l0.481-0.445h0.946l0.519,0.52l1.41,0.146l-0.187,1.875
			l1.565,2.763l-0.824,1.575l0.056,0.742l0.649,0.647v1.784l0.852,1.146v1.482h0.736c-4.096,5.029-10.33,8.25-17.305,8.25
			C12.009,46.625,2,36.615,2,24.312c0-3.097,0.636-6.049,1.781-8.732v-0.696l0.798-0.969c0.277-0.523,0.574-1.033,0.891-1.53
			l0.036,0.405l-0.926,1.125c-0.287,0.542-0.555,1.096-0.798,1.665v1.27l0.927,0.446v1.765l0.889,1.517l0.723,0.111l0.093-0.52
			l-0.853-1.316l-0.167-1.279h0.5l0.211,1.316l1.233,1.799L7.02,21.27l0.784,1.199l1.947,0.482v-0.315l0.779,0.111l-0.074,0.556
			l0.612,0.112l0.945,0.258l1.335,1.521l1.705,0.129l0.167,1.391l-1.167,0.816l-0.055,1.242l-0.167,0.76l1.688,2.113l0.129,0.724
			c0,0,0.612,0.166,0.687,0.166c0.074,0,1.372,0.983,1.372,0.983v3.819l0.463,0.13l-0.315,1.762l0.779,1.039l-0.144,1.746
			l1.029,1.809l1.321,1.154l1.328,0.024l0.13-0.427l-0.976-0.822l0.056-0.408l0.175-0.5l0.037-0.51l-0.66-0.02l-0.333-0.418
			l0.548-0.527l0.074-0.398l-0.612-0.175l0.036-0.37l0.872-0.132l1.326-0.637l0.445-0.816l1.391-1.78l-0.316-1.392l0.427-0.741
			l1.279,0.039l0.861-0.682l0.278-2.686l0.955-1.213l0.167-0.779l-0.871-0.279l-0.575-0.943l-1.965-0.02l-1.558-0.594l-0.074-1.111
			l-0.52-0.909l-1.409-0.021l-0.814-1.278l-0.723-0.353l-0.037,0.39l-1.316,0.078l-0.482-0.671l-1.373-0.279l-1.131,1.307
			l-1.78-0.302l-0.129-2.006l-1.299-0.222l0.521-0.984l-0.149-0.565l-1.707,1.141l-1.074-0.131L9.48,21.016l0.234-0.865l0.592-1.091
			l1.363-0.69l2.632-0.001l-0.007,0.803l0.946,0.44l-0.075-1.372l0.682-0.686l1.376-0.904l0.094-0.636l1.372-1.428l1.459-0.808
			l-0.129-0.106l0.988-0.93l0.362,0.096l0.166,0.208l0.375-0.416l0.092-0.041l-0.411-0.058l-0.417-0.139v-0.4l0.221-0.181h0.487
			l0.223,0.098l0.193,0.39l0.236-0.036v-0.034l0.068,0.023l0.684-0.105l0.097-0.334l0.39,0.098v0.362l-0.362,0.249h0.001
			l0.053,0.397l1.239,0.382c0,0,0.001,0.005,0.003,0.015l0.285-0.024l0.019-0.537l-0.982-0.447l-0.056-0.258l0.815-0.278l0.036-0.78
			l-0.852-0.519l-0.056-1.315l-1.168,0.574h-0.426l0.112-1.001l-1.59-0.375l-0.658,0.497v1.516l-1.183,0.375l-0.474,0.988
			l-0.514,0.083v-1.264l-1.112-0.154l-0.556-0.362l-0.224-0.819l1.989-1.164l0.973-0.296l0.098,0.654l0.542-0.028l0.042-0.329
			l0.567-0.081l0.01-0.115l-0.244-0.101l-0.056-0.348l0.697-0.059l0.421-0.438l0.023-0.032l0.005,0.002l0.128-0.132l1.465-0.185
			l0.648,0.55l-1.699,0.905l2.162,0.51l0.28-0.723h0.945l0.334-0.63l-0.668-0.167V6.212L22.69,5.284l-1.446,0.167l-0.816,0.427
			l0.056,1.038l-0.853-0.13L19.5,6.212l0.817-0.742l-1.483-0.074l-0.426,0.129l-0.185,0.5l0.556,0.094l-0.111,0.556l-0.945,0.056
			l-0.148,0.37l-1.371,0.038c0,0-0.038-0.778-0.093-0.778c-0.055,0,1.075-0.019,1.075-0.019l0.817-0.798l-0.446-0.223l-0.593,0.576
			l-0.984-0.056l-0.593-0.816h-1.261L12.81,6.008h1.206l0.11,0.353l-0.313,0.291l1.335,0.037l0.204,0.482l-1.503-0.056l-0.073-0.371
			L12.831,6.54L12.33,6.262l-1.125,0.009C14.888,3.588,19.417,2,24.312,2c5.642,0,10.797,2.109,14.73,5.574l-0.265,0.474
			l-1.029,0.403l-0.434,0.471l0.1,0.549l0.531,0.074l0.32,0.8l0.916-0.369l0.151,1.07h-0.276l-0.752-0.111l-0.834,0.14l-0.807,1.14
			l-1.154,0.181l-0.167,0.988l0.487,0.115l-0.141,0.635l-1.146-0.23l-1.051,0.23l-0.223,0.585l0.182,1.228l0.617,0.289l1.035-0.006
			l0.699-0.063l0.213-0.556l1.092-1.419l0.719,0.147l0.708-0.64l0.132,0.5l1.742,1.175l-0.213,0.286l-0.785-0.042l0.302,0.428
			l0.483,0.106l0.566-0.236l-0.012-0.682l0.251-0.126l-0.202-0.214l-1.162-0.648l-0.306-0.861h0.966l0.309,0.306l0.832,0.717
			l0.035,0.867l0.862,0.918l0.321-1.258l0.597-0.326l0.112,1.029l0.583,0.64l1.163-0.02c0.225,0.579,0.427,1.168,0.604,1.769
			L45.592,18.139z M13.261,11.046l0.584-0.278l0.528,0.126l-0.182,0.709l-0.57,0.181L13.261,11.046z M16.36,12.715v0.459h-1.334
			l-0.5-0.139l0.125-0.32l0.641-0.265h0.876v0.265H16.36z M16.974,13.355V13.8l-0.334,0.215l-0.416,0.077c0,0,0-0.667,0-0.737
			H16.974z M16.598,13.174v-0.529l0.459,0.418L16.598,13.174z M16.807,14.244v0.433l-0.319,0.32h-0.709l0.111-0.486l0.335-0.029
			l0.069-0.167L16.807,14.244z M15.041,13.355h0.737l-0.945,1.321l-0.39-0.209l0.084-0.556L15.041,13.355z M18.059,14.092v0.432
			H17.35l-0.194-0.28v-0.402h0.056L18.059,14.092z M17.404,13.498l0.202-0.212l0.341,0.212l-0.273,0.225L17.404,13.498z
			 M45.954,19.265l0.07-0.082c0.029,0.126,0.06,0.252,0.088,0.38L45.954,19.265z"/>
		<path d="M3.782,14.884v0.696c0.243-0.568,0.511-1.122,0.798-1.665L3.782,14.884z"/>
	</g>
</svg>
          {{ $t("switch_table.map") }}
          <!-- eslint-enable -->
        </span>
        <span v-if="!getIsMobile" :class="{active:getListPage}" @click="setListPage">
          <svg version="1.1" height="21" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
          <g>
            <path d="M42.5,22h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,22,42.5,22z"/>
            <path d="M17.5,16h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S16.948,16,17.5,16z"/>
            <path d="M42.5,30h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,30,42.5,30z"/>
            <path d="M42.5,38h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,38,42.5,38z"/>
            <path d="M42.5,46h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,46,42.5,46z"/>
            <path
                d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"/>
          </g>
          </svg>
          {{ $t("stations") }}
          <!-- eslint-enable -->
        </span>
        <span v-if="getAuthStatus && !getIsMobile" :class="{active:getFavoritesPage}" @click="setFavoritesPage">
          <!-- eslint-disable -->
            <svg version="1.1" height="21" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 485 485" style="enable-background:new 0 0 485 485;" xml:space="preserve">
                      <path fill="#000" d="M343.611,22.543c-22.613,0-44.227,5.184-64.238,15.409c-13.622,6.959-26.136,16.205-36.873,27.175
                        c-10.738-10.97-23.251-20.216-36.873-27.175c-20.012-10.225-41.625-15.409-64.239-15.409C63.427,22.543,0,85.97,0,163.932
                        c0,55.219,29.163,113.866,86.678,174.314c48.022,50.471,106.816,92.543,147.681,118.95l8.141,5.261l8.141-5.261
                        c40.865-26.406,99.659-68.479,147.682-118.95C455.838,277.798,485,219.151,485,163.932C485,85.97,421.573,22.543,343.611,22.543z
                         M376.589,317.566c-42.918,45.106-95.196,83.452-134.089,109.116c-38.893-25.665-91.171-64.01-134.088-109.116
                        C56.381,262.884,30,211.194,30,163.932c0-61.42,49.969-111.389,111.389-111.389c35.361,0,67.844,16.243,89.118,44.563
                        l11.993,15.965l11.993-15.965c21.274-28.32,53.757-44.563,89.118-44.563c61.42,0,111.389,49.969,111.389,111.389
                        C455,211.194,428.618,262.884,376.589,317.566z"/>
                </svg>
          {{ $t("switch_table.favorite_stations") }}
          <!-- eslint-enable -->
        </span>
        <span v-if="getAuthStatus && getIsMobile" :class="{active:getHistoryPage}" @click="setHistoryPage">
          <svg version="1.1" height="21" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
          <g>
            <path d="M42.5,22h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,22,42.5,22z"/>
            <path d="M17.5,16h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S16.948,16,17.5,16z"/>
            <path d="M42.5,30h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,30,42.5,30z"/>
            <path d="M42.5,38h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,38,42.5,38z"/>
            <path d="M42.5,46h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,46,42.5,46z"/>
            <path
                d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"/>
          </g>
          </svg>
          {{ $t("message.top_menu_history") }}
          <!-- eslint-enable -->
        </span>
        <span v-if="getAuthStatus && getIsMobile" :class="{active:getBalancePage}" @click="setBalancePage">
          <!-- eslint-disable -->
            <svg fill="#62A321" height="21" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
              <path d="M438.154,44.308H73.846C33.127,44.308,0,77.435,0,118.154v275.692c0,40.719,33.127,73.846,73.846,73.846h364.308
                c40.719,0,73.846-33.127,73.846-73.846V118.154C512,77.435,478.873,44.308,438.154,44.308z M482.462,300.308H374.154
                c-24.431,0-44.308-19.876-44.308-44.308s19.876-44.308,44.308-44.308h108.308V300.308z M482.462,182.154H374.154
                c-40.719,0-73.846,33.127-73.846,73.846s33.127,73.846,73.846,73.846h108.308v64c0,24.431-19.876,44.308-44.308,44.308H73.846
                c-24.431,0-44.308-19.876-44.308-44.308V118.154c0-24.431,19.876-44.308,44.308-44.308h364.308
                c24.431,0,44.308,19.876,44.308,44.308V182.154z"/>
            </g>
              <g>
              <path d="M393.846,241.231h-19.692c-8.157,0-14.769,6.613-14.769,14.769c0,8.157,6.613,14.769,14.769,14.769h19.692
                c8.157,0,14.769-6.613,14.769-14.769C408.615,247.843,402.003,241.231,393.846,241.231z"/>
            </g>
          </svg>
          {{ $t("payments") }}
          <!-- eslint-enable -->
        </span>
        <span v-if="chargeStatus" @click="setActiveSessionModal" class="charge_link" title="Active charge">
          <!-- eslint-disable -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" height="21">
              <g id="Electric_Plug-2" data-name="Electric Plug">
                <path fill="#62A321"
                      d="M44.85,27.47A1,1,0,0,0,44,27H33V12a1,1,0,0,0-1.89-.45l-12,24A1,1,0,0,0,20,37H31V52a1,1,0,0,0,.77.97A.908.908,0,0,0,32,53a1,1,0,0,0,.89-.55l12-24A1.007,1.007,0,0,0,44.85,27.47ZM33,47.76V36a1,1,0,0,0-1-1H21.62L31,16.24V28a1,1,0,0,0,1,1H42.38Z"/>
                <path class="rotatatata" fill="#62A321"
                      d="M56.41,14.58a4.988,4.988,0,0,0-.37-6.62L53.21,5.13a1.024,1.024,0,0,0-1.41,0l-.71.71L48.26,3.01,46.85,4.42l2.83,2.83L48.26,8.67,45.43,5.84,44.02,7.25l2.83,2.83-.71.71a1,1,0,0,0,0,1.41l2.83,2.83a4.936,4.936,0,0,0,5.9.84A27.678,27.678,0,0,1,60,32,28.025,28.025,0,0,1,21.38,57.92l-.76,1.85A30.025,30.025,0,0,0,62,32,29.669,29.669,0,0,0,56.41,14.58Zm-1.78-.96a3.03,3.03,0,0,1-4.25,0l-2.12-2.13,4.25-4.24,2.12,2.12A3.012,3.012,0,0,1,54.63,13.62Z"/>
                <path class="rotatatata" fill="#62A321"
                      d="M32,2A30.037,30.037,0,0,0,2,32,29.669,29.669,0,0,0,7.59,49.42a4.988,4.988,0,0,0,.37,6.62l2.83,2.83a.967.967,0,0,0,.7.29.99.99,0,0,0,.71-.29l.71-.71,2.83,2.83,1.41-1.41-2.83-2.83,1.42-1.42,2.83,2.83,1.41-1.41-2.83-2.83.71-.71a1,1,0,0,0,0-1.41l-2.83-2.83a4.987,4.987,0,0,0-5.9-.84A27.678,27.678,0,0,1,4,32,28.031,28.031,0,0,1,32,4,27.733,27.733,0,0,1,42.62,6.09l.76-1.86A29.93,29.93,0,0,0,32,2ZM9.37,50.38a3.03,3.03,0,0,1,4.25,0l2.12,2.13-4.25,4.24L9.37,54.63A3.012,3.012,0,0,1,9.37,50.38Z"/>
              </g>
            </svg>
          <!-- eslint-enable -->
          {{ $t("bot_menu_status") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "BottomMenu",
  computed: {
    ...mapGetters([
      'getAuthStatus',
      'getAccountInfo',
      'getProfilePage',
      'getHistoryPage',
      'getIsMobile',
      'getBalancePage',
      'getListPage',
      'getFavoritesPage',
      'getMainPage',
      'getActiveSessionModal',
      'getSocketData'
    ]),
    chargeStatus() {
      if (this.$store.getters.getSocketData !== null && this.$store.getters.getSocketFinishedData === null || typeof this.getAccountInfo.Sessions === 'object' && !Array.isArray(this.getAccountInfo.Sessions) && this.getAccountInfo.Sessions !== null) {
        if (typeof this.getAccountInfo.Sessions === 'object' && !Array.isArray(this.getAccountInfo.Sessions) && this.getAccountInfo.Sessions !== null) {
          return true
        }
        let x = this.$store.getters.getSocketData.Event
        if (x === 'tick' || x === 'started' && x !== 'canceled' && x !== 'finished') {
          return true
        }
      }
      return false
    }
  },
  methods: {
    openMobileMenu() {
      this.setMainPage()
      this.$store.commit('setMobileMenuStatus', true)
      let browser = window.navigator.userAgent.substr(-40,)
      let firefox = browser.indexOf('Firefox')
      let mi = browser.indexOf('MiuiBrowser')
      if (firefox >= 0 || mi >= 0) {
        setTimeout(() => document.querySelector('.mobile_menu').style.background = 'rgba(255, 255, 255, 0.95)', 10)
      }
    },
    setAuthModal(boolean) {
      this.$store.state.AuthModal = boolean
    },
    setActiveSessionModal() {
      let fromx = this.$store.getters.getAccountInfo.Sessions.LocationId
      let fromy = this.$store.getters.getStations
      let outer = fromy.find(fromy => fromy.Key === fromx)
      this.$parent.$emit('flyToActiveSpot', outer)
    },
    setListPage() {
      this.$store.commit('setListPage')
    },
    setFavoritesPage() {
      this.$store.commit('setFavoritesPage')
    },
    setHistoryPage() {
      this.$store.commit('setHistoryPage')
    },
    setBalancePage() {
      this.$store.commit('setBalancePage')
    },
    setMainPage() {
      this.$store.commit('setMainPage')
    },
    setLegend(boolean) {
      this.$store.commit('setLegend', boolean)
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom_board {
  transition: transform 0.8s ease;
  z-index: 401;
  user-select: none;
  padding: 0 15px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  .bottom_board_inner {
    display: flex;
    height: 100%;
    align-items: center;

    .change_view_block {
      color: #62A321;
      display: flex;
      height: 100%;
      align-items: center;

      .mobile_nav_icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-width: 80px;

        .hamburger_icon {
          top: 3px;
          height: 3px;
          position: relative;
          width: 30px;
          background-color: #62A321;
          transition: all ease 0.3s;

          &::before, &::after {
            content: "";
            position: absolute;
            height: inherit;
            border-radius: inherit;
            background-color: inherit;
            margin: auto;
            width: 100%;
            transition: all ease 0.3s;
          }

          &::before {
            top: -7px;
            left: 0;
            transform-origin: left;
          }

          &::after {
            bottom: -7px;
            right: 0;
            transform-origin: right;
          }
        }

        p {
          margin: 0;
          color: #000;
          font-weight: 400;
          font-size: 0.8rem;
          bottom: -10px;
          position: relative;
        }
      }

      span {
        cursor: pointer;
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 2px;
        overflow: hidden;
        transition: box-shadow .1s linear;
        color: #000;
        font-weight: 400;
        font-size: 0.8rem;
        width: 100px;

        svg {
          transition: all .1s linear;

          path {
            transition: all .1s linear;
          }
        }

        &.active svg path {
          fill: #62A321;
          transition: all .1s linear;
        }

        &.active {
          color: #62A321;
          transition: all .1s linear;
        }

        &:hover:not(.charge_link) {
          transition: all .1s linear;
          color: #62A321;

          svg {
            transition: all .1s linear;
          }
        }

        &:hover:not(.charge_link) svg path {
          fill: #62A321;
        }

        &.active:hover {
          transition: all .1s linear;
          cursor: default;

          svg {
            transform: scale(1);
          }
        }
      }

      .charge_link {
        svg {
          display: flex;
          overflow: visible;
          transform: scale(1.3);

          .rotatatata {
            animation-name: rotation;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            transform-origin: center;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(-360deg);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .bottom_board {
    .bottom_board_inner {
      .change_view_block {
        .set_main_page {
          display: none!important;
        }
        span:not(.active):not(.charge_link) {
          &:hover {
            color: #000 !important;
          }

          svg {
            transform: scale(1) !important;

            path {
              fill: #000 !important;
            }
          }
        }

        span.charge_link {
          svg {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .bottom_board {
    .bottom_board_inner {
      width: 100%;

      .change_view_block {
        width: 100%;
        padding: 0 10px;
        justify-content: space-evenly;

        span {
          width: fit-content;
          min-width: 80px;
        }
      }
    }
  }
}
</style>
