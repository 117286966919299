<template>
  <div class="balance_page">
    <div @click="closeModalFromOverlay" v-if="isModalOpen" class="pdf-modal">
      <div class="pdf-modal-inner">
        <div class="close-pdf-modal" @click="closeModal">x</div>
        <div class="pdf">
          <vue-pdf-embed :source="PDFbase" />
        </div>
      </div>
    </div>
    <div class="balance_page_inner">
      <div class="custom_container">
        <div class="balance_history_outer">
          <div class="block_title">
            <h3>{{ $t("payments") }}
              <div class="close_page">
                <a @click.prevent="MainPageActive" href="">
                  <svg height="15px" viewBox="0 0 311 311.07733" width="15px" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff"
                      d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
                    <path fill="#fff"
                      d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
                  </svg>
                </a>
              </div>
            </h3>
          </div>

          <div v-if="!this.$store.getters.getIsProd" class="bank_cards">
            <div class="block_subtitile">
              Bank cards
            </div>
            <div class="bank_cards_inner">
              <div class="bank_card">
                <div class="bank_card_inner">
                  <div class="bank_card_default">Default</div>
                  <div class="d-flex">
                    <div class="divka">
                      <div class="bank_card_number">****6424</div>
                      <div class="bank_card_expire">04/24</div>
                    </div>
                    <div class="bank_card_type visa"><img src="/img/visa.png" alt=""></div>
                  </div>
                  <div class="bank_card_holder">ALEKSANDR POPOV</div>
                </div>
                <div class="bank_card_actions">
                  <div class="set_active">Disable</div>
                  <div class="remove">Remove</div>
                </div>
              </div>
              <div class="bank_card">
                <div class="bank_card_inner disabled">
                  <div class="bank_card_default">Default</div>
                  <div class="d-flex">
                    <div class="divka">
                      <div class="bank_card_number">****7358</div>
                      <div class="bank_card_expire">11/26</div>
                    </div>
                    <div class="bank_card_type mastercard"><img src="/img/mastercard.png" alt=""></div>
                  </div>
                  <div class="bank_card_holder">ALEKSANDR POPOV</div>
                </div>
                <div class="bank_card_actions">
                  <div class="set_deafault">Set default</div>
                  <div class="set_active">Disable</div>
                  <div class="remove">Remove</div>
                </div>
              </div>
              <div class="bank_card_add">
                <div class="plas"><span>+</span></div>
                <div class="bank_card_add_text">Add card</div>
              </div>
            </div>
          </div>

          <div class="block_subtitile">
            {{ $t("onetime_pay") }}
          </div>
          <form class="up_balance">
            <div class="flex_outer">
              <div class="flex_inner">
                <div class="input_outer">
                  <input oninput="this.value = this.value.replace(/[^0-9]/g, '')" ref="amount" min="10" max="100"
                    v-model.number="amountInput" type="number">
                  <span class="minmax">Min 10 BGN, Max 100 BGN</span>
                  <span class="currency">BGN</span>
                </div>
                <button type="submit" :disabled="checkAmountInput" @click="submitAmount">{{ $t("top_up") }}</button>
              </div>
              <div class="btns">
                <button type="submit" :disabled="checkAmountInput" @click="submitCustomAmount(20)">20</button>
                <button type="submit" :disabled="checkAmountInput" @click="submitCustomAmount(30)">30</button>
                <button type="submit" :disabled="checkAmountInput" @click="submitCustomAmount(40)">40</button>
                <button type="submit" :disabled="checkAmountInput" @click="submitCustomAmount(50)">50</button>
              </div>
            </div>
            <div class="payment_infobar">
              <span>{{ $t("payment_secure") }}</span>
            </div>
          </form>

          <h3>{{ $t("payment_history") }}</h3>
          <div class="balance_history_table">
            <div v-if="isMobile === false" class="table_desktop">
              <div class="table_head_row">
                <div class="table_head_col h1">{{ $t("date") }}</div>
                <div class="table_head_col h2">{{ $t("type") }}</div>
                <div class="table_head_col h3">{{ $t("transaction_code") }}</div>
                <!-- <div class="table_head_col h4">{{ $t("note") }}</div> -->
                <div class="table_head_col h4">{{ $t("pay_incomer") }}</div>
                <div class="table_head_col h5">{{ $t("sum") }}</div>
                <div class="table_head_col h6">{{ $t("pay_method") }}</div>
              </div>
              <transition name="fade">
                <div v-if="loading === false" class="table_body">
                  <div v-for="(item, index) in paginatedData" :key="index" class="table_body_row">
                    <div class="table_body_col b1">
                      {{ subtractThreeHours(item.Dt, 3) }}
                    </div>
                    <div class="table_body_col b2">
                      <span>{{ item.ActionTitle }}</span>
                    </div>
                    <div class="table_body_col b3">
                      {{ item.Comment }}
                    </div>
                    <!-- <div class="table_body_col b4">
                      {{ item.Details.replace("amp;", "") }}
                    </div> -->
                    <div class="table_body_col b4">
                      Волтспот ООД (202648040)
                    </div>
                    <div :class="{ green: Number(item.ActionId) === 1 }" class="table_body_col b5">
                      {{ item.Amount }} {{ item.SysCurrencyIso }}
                    </div>
                    <div class="table_body_col b6">
                      Mypos vPOS
                    </div>
                  </div>
                </div>
              </transition>
              <img v-if="loading === true" src="/images/new_spinner.gif" alt="" class="spinner">
            </div>

            <div v-else class="table_mobile">
              <transition name="fade">
                <div class="table_mobile_inner">
                  <div v-for="(item, index) in balanceHistory" :key="index" class="table_mobile_item">
                    <div class="item_row">
                      <span>{{ $t("date") }}</span><span>{{ subtractThreeHours(item.Dt, 3) }}</span>
                    </div>
                    <hr>
                    <div class="item_row">
                      <span>{{ $t("type") }}</span><span>{{ item.ActionTitle }}</span>
                    </div>
                    <hr>
                    <div class="item_row">
                      <span>{{ $t("transaction_code") }}</span><span>{{ item.Comment }}</span>
                    </div>
                    <hr>
                    <!-- <div class="item_row">
                      <span>{{ $t("note") }}</span><span>{{ item.Details.replace("amp;", "") }}</span>
                    </div> -->
                    <div class="item_row">
                      <span>{{ $t("pay_incomer") }}</span><span>Волтспот ООД (202648040)</span>
                    </div>
                    <hr>
                    <div class="item_row red">
                      <span>{{ $t("sum") }}</span>
                      <span :class="{ green: Number(item.ActionId) === 1 }">
                        {{ item.Amount }} {{ item.SysCurrencyIso }}
                      </span>
                    </div>
                    <hr>
                    <div class="item_row">
                      <span>{{ $t("pay_method") }}</span><span>Mypos vPOS</span>
                    </div>
                    <div v-if="item.Filename" class="item_row tax">
                      <div @click="downloadFile(item)" class="tax-download-btn">
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.625 15C5.625 14.5858 5.28921 14.25 4.875 14.25C4.46079 14.25 4.125 14.5858 4.125 15H5.625ZM4.875 16H4.125H4.875ZM19.275 15C19.275 14.5858 18.9392 14.25 18.525 14.25C18.1108 14.25 17.775 14.5858 17.775 15H19.275ZM11.1086 15.5387C10.8539 15.8653 10.9121 16.3366 11.2387 16.5914C11.5653 16.8461 12.0366 16.7879 12.2914 16.4613L11.1086 15.5387ZM16.1914 11.4613C16.4461 11.1347 16.3879 10.6634 16.0613 10.4086C15.7347 10.1539 15.2634 10.2121 15.0086 10.5387L16.1914 11.4613ZM11.1086 16.4613C11.3634 16.7879 11.8347 16.8461 12.1613 16.5914C12.4879 16.3366 12.5461 15.8653 12.2914 15.5387L11.1086 16.4613ZM8.39138 10.5387C8.13662 10.2121 7.66533 10.1539 7.33873 10.4086C7.01212 10.6634 6.95387 11.1347 7.20862 11.4613L8.39138 10.5387ZM10.95 16C10.95 16.4142 11.2858 16.75 11.7 16.75C12.1142 16.75 12.45 16.4142 12.45 16H10.95ZM12.45 5C12.45 4.58579 12.1142 4.25 11.7 4.25C11.2858 4.25 10.95 4.58579 10.95 5H12.45ZM4.125 15V16H5.625V15H4.125ZM4.125 16C4.125 18.0531 5.75257 19.75 7.8 19.75V18.25C6.61657 18.25 5.625 17.2607 5.625 16H4.125ZM7.8 19.75H15.6V18.25H7.8V19.75ZM15.6 19.75C17.6474 19.75 19.275 18.0531 19.275 16H17.775C17.775 17.2607 16.7834 18.25 15.6 18.25V19.75ZM19.275 16V15H17.775V16H19.275ZM12.2914 16.4613L16.1914 11.4613L15.0086 10.5387L11.1086 15.5387L12.2914 16.4613ZM12.2914 15.5387L8.39138 10.5387L7.20862 11.4613L11.1086 16.4613L12.2914 15.5387ZM12.45 16V5H10.95V16H12.45Z"
                            fill="#fff" />
                        </svg>
                      </div>
                      <div class="tax-open-btn" @click="openModal(item);">
                        <svg fill="#ffffff" height="40px" width="40px" viewBox="0 0 512 512" xml:space="preserve"
                          stroke="#ffffff">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <path
                                  d="M427.023,0H84.977C71.126,0,59.858,11.268,59.858,25.119v461.762c0,13.851,11.268,25.119,25.119,25.119h265.086 c2.126,0,4.166-0.844,5.668-2.348l94.063-94.063c1.504-1.503,2.348-3.542,2.348-5.668V25.119C452.142,11.268,440.874,0,427.023,0z M358.079,484.63v-57.607c0-5.01,4.076-9.086,9.086-9.086h57.607L358.079,484.63z M436.109,401.904h-68.944 c-13.851,0-25.119,11.268-25.119,25.119v68.944H84.977c-5.01,0-9.086-4.076-9.086-9.086V25.119c0-5.01,4.076-9.086,9.086-9.086 h342.046c5.01,0,9.086,4.076,9.086,9.086V401.904z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M204.693,68.409h-68.409c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h26.188v77.495 c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017V84.443h26.188c4.427,0,8.017-3.589,8.017-8.017 S209.12,68.409,204.693,68.409z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M289.332,159.634l-23.826-79.418c-2.119-7.062-8.496-11.807-15.869-11.807h-4.378c-7.373,0-13.75,4.745-15.869,11.807 l-23.826,79.418c-1.272,4.241,1.134,8.71,5.375,9.982c4.241,1.275,8.71-1.134,9.982-5.374l3.416-11.39h46.219l3.417,11.389 c1.042,3.473,4.227,5.715,7.676,5.715c0.762,0,1.538-0.11,2.307-0.34C288.198,168.344,290.605,163.875,289.332,159.634z M229.149,136.818l15.598-51.995c0.067-0.224,0.278-0.381,0.512-0.381h4.378c0.234,0,0.445,0.156,0.512,0.381l15.598,51.995 H229.149z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M373.732,157.34l-26.712-38.158l26.712-38.158c2.539-3.627,1.657-8.626-1.97-11.165c-3.627-2.539-8.626-1.658-11.165,1.97 l-23.362,33.374l-23.362-33.374c-2.539-3.628-7.539-4.51-11.165-1.97c-3.628,2.539-4.51,7.538-1.97,11.165l26.712,38.158 L300.74,157.34c-2.539,3.627-1.657,8.626,1.97,11.165c1.399,0.98,3.003,1.449,4.59,1.449c2.527,0,5.015-1.192,6.574-3.42 l23.362-33.374l23.362,33.374c1.56,2.228,4.047,3.42,6.574,3.42c1.587,0,3.192-0.47,4.59-1.449 C375.389,165.966,376.272,160.967,373.732,157.34z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M136.284,213.779h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017S140.711,213.779,136.284,213.779z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M136.284,247.983h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017S140.711,247.983,136.284,247.983z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M136.284,282.188h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017S140.711,282.188,136.284,282.188z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M392.818,213.779h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017S397.246,213.779,392.818,213.779z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M392.818,247.983h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017S397.246,247.983,392.818,247.983z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M392.818,282.188h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017S397.246,282.188,392.818,282.188z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M273.102,213.779H170.489c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h102.614 c4.427,0,8.017-3.589,8.017-8.017S277.53,213.779,273.102,213.779z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M238.898,247.983h-68.409c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h68.409 c4.427,0,8.017-3.589,8.017-8.017S243.325,247.983,238.898,247.983z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M273.102,282.188H170.489c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h102.614 c4.427,0,8.017-3.589,8.017-8.017S277.53,282.188,273.102,282.188z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M152.852,387.18v-27.859c5.907,1.936,9.62,5.833,9.62,9.447c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017 c0-12.784-10.768-23.198-25.653-25.984v-1.273c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017v1.273 c-14.885,2.786-25.653,13.2-25.653,25.984c0,17.862,14.265,25.369,25.653,29.69v27.859c-5.907-1.936-9.62-5.834-9.62-9.447 c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017c0,12.784,10.768,23.198,25.653,25.984v1.273 c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-1.273c14.885-2.786,25.653-13.2,25.653-25.984 C178.505,399.007,164.24,391.5,152.852,387.18z M136.818,380.966c-7.992-3.916-9.62-7.337-9.62-12.198 c0-3.614,3.713-7.511,9.62-9.447V380.966z M152.852,426.315V404.67c7.992,3.916,9.62,7.337,9.62,12.198 C162.472,420.482,158.758,424.38,152.852,426.315z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M264.551,350.597h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858 c4.427,0,8.017-3.589,8.017-8.017S268.979,350.597,264.551,350.597z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M238.898,419.006h-34.205c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017C246.914,422.596,243.325,419.006,238.898,419.006z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M290.205,419.006h-17.102c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h17.102 c4.427,0,8.017-3.589,8.017-8.017C298.221,422.596,294.632,419.006,290.205,419.006z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M315.858,384.802H204.693c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h111.165 c4.427,0,8.017-3.589,8.017-8.017C323.875,388.391,320.285,384.802,315.858,384.802z">
                                </path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="273.102" cy="256" r="8.017"></circle>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="307.307" cy="256" r="8.017"></circle>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="341.511" cy="256" r="8.017"></circle>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="307.307" cy="221.795" r="8.017"></circle>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="341.511" cy="221.795" r="8.017"></circle>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="307.307" cy="290.205" r="8.017"></circle>
                              </g>
                            </g>
                            <g>
                              <g>
                                <circle cx="341.511" cy="290.205" r="8.017"></circle>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <img v-if="loading === true" src="/images/new_spinner.gif" alt="" class="spinner">
            </div>

          </div>
        </div>
      </div>
      <div v-show="isMobile === false && pageCount > 1" class="pagination">
        <button class="prev" :disabled="pageNumber === 0" @click="prevPage">&lt;</button>
        <span>{{ pageNumber + 1 }} / {{ pageCount }}</span>
        <button class="next" :disabled="pageNumber >= pageCount - 1" @click="nextPage">&gt;</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from 'moment'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { mapGetters } from "vuex";

export default {
  components: {
    VuePdfEmbed,
  },
  name: "BalancePage",
  data: function () {
    return {
      balanceHistory: [],
      pageNumber: 0,
      size: 8,
      loading: true,
      amountInput: 10,
      PDFbase: "",
      isModalOpen: false
    }
  },
  methods: {
    closeModalFromOverlay(e) {
      if (e.target.classList.contains('pdf-modal')) {
        this.isModalOpen = false;
      }
    },
    openModal(item) {
      this.getPDF(item)
        .then(dataPDF => {
          if (!dataPDF) {
            console.log('Ошибка при получении чека');
            return false;
          }
          this.PDFbase = 'data:application/pdf;base64,' + dataPDF;
          this.isModalOpen = true;
        })
        .catch(error => {
          console.error('Ошибка при получении чека', error);
        });
    },
    closeModal() {
      this.isModalOpen = false;
      this.PDFbase = "";
    },
    getPDF(item) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'https://voltspot.net/url/cors.php',
          method: 'GET',
          params: {
            url: 'http://api2.voltspot.net/app/task/receipt/print/' + item.Key + '/?format=json',
          }
        })
          .then(response => {
            if (response.data.rdata.Content) {
              resolve(response.data.rdata.Content);
            } else {
              reject(new Error('Отсутствует содержимое в ответе'));
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    downloadFile(item) {
      const transactionDate = new Date(item.Dt);
      const year = transactionDate.getFullYear();
      const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
      const day = transactionDate.getDate().toString().padStart(2, '0');
      const dateToFile = year + month + day;
      const amount = parseFloat(item.Amount);

      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'GET',
        params: {
          url: 'http://api2.voltspot.net/app/task/receipt/print/' + item.Key + '/?format=json',
        }
      })
        .then(response => {
          try {
            const base = response.data.rdata.Content;
            var binaryData = atob(base);
            var byteArray = new Uint8Array(binaryData.length);
            for (var i = 0; i < binaryData.length; i++) {
              byteArray[i] = binaryData.charCodeAt(i);
            }
            var blob = new Blob([byteArray], { type: 'application/pdf' });
            var downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `VoltspotReceipt-${dateToFile}-${amount}BGN-${item.Key}.pdf`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          } catch (e) {
            console.log('Ошибка при скачивании PDF: ' + e);
          }
        })
    },
    submitAmount() {
      let parsedAmountInput = parseInt(this.amountInput)
      let url = 'https://voltspot.net/pay/index.php?f_token=' + localStorage.getItem('AuthToken') + '&f_amount=' + parsedAmountInput
      window.open(url)
      this.amountInput = ''
    },
    submitCustomAmount(sum) {
      let url = 'https://voltspot.net/pay/index.php?f_token=' + localStorage.getItem('AuthToken') + '&f_amount=' + sum
      window.open(url)
    },
    MainPageActive() {
      this.$store.commit('setMainPage')
    },
    nextPage() {
      this.pageNumber++
    },
    prevPage() {
      this.pageNumber--
    },
    subtractThreeHours(date, hour = 0) {
      try {
        const d = new Date(date);
        d.setHours(d.getHours() - hour);
        return moment(d).format('DD-MM-YYYY H:mm');
      } catch (e) {
        console.log('Ошибка преобразования даты')
        return 0;
      }
    },
  },
  mounted() {
    let _this = this
    let browser = window.navigator.userAgent.substr(-40,)
    let firefox = browser.indexOf('Firefox')
    let mi = browser.indexOf('MiuiBrowser')
    if (firefox >= 0 || mi >= 0) {
      document.querySelector('.balance_page_inner').style.background = 'rgba(0, 0, 0, 0.8)'
    }

    this.loading = true
    let balanceUrl = process.env.VUE_APP_API_URL + '/app/besp/balance/history/?o_record_per_page=999&format=json&f_token=' + localStorage.getItem('AuthToken')

    axios({
      url: 'https://voltspot.net/url/cors.php',
      method: 'POST',
      params: {
        url: balanceUrl
      }
    })
      .then(resp => {
        if (resp.data.rcode >= 0) {
          let refill = resp.data.rdata.Records.filter(item => item.Action === 'REFILL')
          _this.balanceHistory = refill
        } else {
          console.log('Ошибка получения баланса: ' + resp.data.rtext)
        }
        _this.loading = false
      })
  },
  computed: {
    ...mapGetters([
      "getAccountInfo",
    ]),
    checkAmountInput() {
      if (this.getAccountInfo.Phone) {
        return this.getAccountInfo.Phone.startsWith('77') || this.getAccountInfo.Phone.startsWith('+77') || this.getAccountInfo.Phone.startsWith('87');
      }
      return !(this.amountInput !== "" && this.amountInput >= 10 && this.amountInput <= 100)
    },
    isMobile() {
      return window.matchMedia('(max-device-width: 575px)').matches
    },
    pageCount() {
      let l = this.balanceHistory.length,
        s = this.size;
      return Math.ceil(l / s)
    },
    paginatedData() {
      const start = this.pageNumber * this.size
      const end = start + this.size
      return this.balanceHistory.slice(start, end)
    },
  },
  created() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }
}
</script>

<style scoped lang="scss">
.pdf-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .pdf-modal-inner {
    position: relative;

    .close-pdf-modal {
      font-size: 20px;
      font-weight: 800;
      position: absolute;
      top: 5px;
      right: 15px;
      z-index: 1;
    }
  }
}

.tax {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  width: 100%;
  margin: 20px 0;
}

.tax-open-btn {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  cursor: pointer;
}

.tax-download-btn {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  cursor: pointer;
}

.bank_cards {
  margin-bottom: 30px;

  .bank_cards_inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    .bank_card_add {
      width: 100%;
      overflow: hidden;
      position: relative;
      align-items: center;
      cursor: pointer;

      .plas {
        background: rgba(0, 0, 0, 0.7);
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.68);
        display: flex;
        border: 1px solid #fff;
        justify-content: center;
        align-items: center;
        height: 100px;
        border-radius: 5px 5px 0 0;
        position: relative;

        span {
          height: 60px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 36px;
          font-weight: 400;
          border: 2px solid #fff;
          line-height: 1;
          border-radius: 50%;
        }
      }

      .bank_card_add_text {
        display: flex;
        justify-content: center;
        grid-gap: 10px;
        padding: 5px 10px;
        color: #000;
        background: rgba(255, 255, 255, 0.7);
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-radius: 0 0 5px 5px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .bank_card {

      .bank_card_inner {
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        border: 1px solid #fff;
        padding: 10px;
        color: #fff;
        overflow: hidden;
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 5px 5px 0 0;
        font-size: 12px;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.68);

        .d-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .divka {
          display: flex;
          width: 100%;
        }

        .bank_card_default {
          text-transform: uppercase;
          font-weight: 800;
        }

        &.disabled {
          .bank_card_default {
            opacity: 0;
          }
        }

        .bank_card_number {
          margin-right: 20px;
        }

        .bank_card_holder {
          text-transform: uppercase;
        }

        .bank_card_expire {}

        .bank_card_type {
          width: 100px;
          display: flex;
          justify-content: flex-end;

          &.visa {
            img {
              max-width: 100%;
            }
          }

          &.mastercard {
            img {
              max-width: 100%;
              max-height: 40px;
            }
          }
        }
      }

      .bank_card_actions {
        display: flex;
        justify-content: space-between;
        grid-gap: 10px;
        padding: 5px 10px;
        text-transform: uppercase;
        font-size: 14px;
        color: #000;
        background: rgba(255, 255, 255, 0.7);
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-radius: 0 0 5px 5px;
        align-items: center;

        & * {
          cursor: pointer;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.balance_page {
  .balance_page_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin-top: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
    z-index: 402;
    overflow-y: scroll;


    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 100%;

      span {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 1.2rem;
        line-height: 1;
        color: #fff;
        width: 100px;
        height: 28px;
        align-items: center;
        flex-wrap: nowrap;
        display: flex;
        justify-content: center;
        user-select: none;
        text-align: center;
      }

      button {
        outline: none;
        display: flex;
        border: 1px solid #fff;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1;
        transition: all .2s linear;
        user-select: none;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

        &.prev {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        &.next {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }

        &:disabled {
          transition: all .2s linear;
          border: 1px solid #fff;
          background: transparent;
        }
      }
    }

    .balance_history_outer {

      .block_title {
        h3 {
          color: #fff;
          font-size: 2.2rem;
          font-weight: 400;
          line-height: 1;
          display: block;
          margin: 0 0 30px 0;
          position: relative;

          .close_page {
            position: absolute;
            top: 0;
            right: 0;

            a {
              background: transparent;
              font-weight: 400;
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.8rem;
              border: 1px solid #fff;
              border-radius: 5px;
              text-decoration: none;
              outline: none;
              box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.4);
              transition: all .2s linear;

              &:hover {
                transition: all .2s linear;
                box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.8);
                background: rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }

      .block_subtitile {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
        display: block;
        margin: 0 0 30px 0;
        position: relative;
      }

      .up_balance {
        margin-bottom: 30px;
        border-radius: 5px;
        align-items: center;
        position: relative;
        width: 100%;
        display: flex;
        padding: 10px;
        background: rgba(0, 0, 0, 0.15);

        .flex_outer {
          display: flex;

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .flex_inner {
            display: flex;

            input[type=number] {
              -moz-appearance: textfield;
            }

            .input_outer {
              position: relative;

              input:focus~.minmax {
                transition: all .1s linear;
                top: -24px;
                opacity: 1;
              }

              .minmax {
                position: absolute;
                top: -14px;
                left: 0;
                right: 0;
                width: calc(100% - 15px);
                height: 25px;
                color: #fff;
                font-weight: 500;
                font-size: 0.8rem;
                transition: all .1s linear;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                z-index: 1;
              }

              .currency {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              }

              input {
                z-index: 2;
                width: 200px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                opacity: 1;
                position: relative;
                border: 1px solid #fff;
                background: transparent;
                color: #fff;
                display: flex;
                padding-left: 10px;
                padding-right: 60px;
                outline: none;
                height: 40px;
                font-size: 1rem;
                box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

                &::placeholder {
                  color: #fff;
                }
              }
            }

            button[type="submit"] {
              background: rgba(0, 0, 0, 0.5);
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              border-bottom: 1px solid #fff;
              border-left: none;
              font-size: 1rem;
              font-weight: 400;
              width: 200px;
              outline: none;
              height: 40px;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
            }

            button:disabled,
            button[disabled] {
              background-color: transparent;
              color: gray;
            }
          }
        }

        .btns {
          display: none;
        }

        .payment_infobar {
          width: calc(100% - 400px);
          text-align: center;

          span {
            color: #fff;
            font-size: 14px;
          }
        }
      }

      h3 {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
        display: block;
        margin: 0 0 20px 0;
        position: relative;
      }

      .balance_history_table {
        &::-webkit-scrollbar {
          display: none;
        }

        .table_desktop {
          display: flex;
          width: 100%;
          min-height: 300px;
          flex-direction: column;
          margin-bottom: 30px;
          position: relative;

          .table_head_row {
            display: flex;
            justify-content: space-between;

            .table_head_col {
              user-select: none;
              color: #fff;
              font-size: 1rem;
              font-weight: 400;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              &.h1 {
                width: 20%;
                padding-left: 20px;
              }

              &.h2 {
                width: 15%;
              }

              &.h3 {
                width: 15%;
              }

              &.h4 {
                width: 25%;
              }

              &.h5 {
                width: 10%;
              }

              &.h6 {
                width: 15%;
              }
            }
          }

          .table_body {
            display: flex;
            width: 100%;
            flex-direction: column;
            min-height: 300px;

            .table_body_row {
              display: flex;
              width: 100%;
              justify-content: space-between;
              background: rgba(0, 0, 0, 0.5);
              margin: 3px 0;
              border: 1px solid #000;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

              &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }

              &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }

              .table_body_col {
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #fff;
                font-size: 1rem;
                overflow: hidden;
                font-weight: 400;
                line-height: 1.5;
                white-space: nowrap;

                &.b1 {
                  width: 20%;
                  padding-left: 20px;
                }

                &.b2 {
                  width: 15%;
                }

                &.b3 {
                  width: 15%;
                }

                &.b4 {
                  width: 25%;
                }

                &.b5 {
                  width: 10%;
                  color: #eb5757;

                  &.green {
                    color: #27ae60;
                  }
                }

                &.b6 {
                  width: 15%;
                }
              }
            }
          }

          .spinner {
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
            width: 50px;
            height: 50px;

            & .path {
              stroke: hsl(210, 70, 75);
              stroke-linecap: round;
              animation: dash 1.5s ease-in-out infinite;
            }
          }
        }

        .table_mobile {
          display: flex;
          width: 100%;
          position: relative;

          .table_mobile_inner {
            width: 100%;

            .table_mobile_item {
              padding: 5px;
              border-radius: 5px;
              display: flex;
              background: rgba(0, 0, 0, 0.6);
              flex-direction: column;
              color: #fff;
              margin-bottom: 10px;
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

              &:last-child {
                margin-bottom: 0;
              }

              .item_row {
                display: flex;

                span {
                  font-size: 0.8rem;
                }

                &.red {
                  span:last-child {
                    color: #EB5757;

                    &.green {
                      color: #32AE5F;
                    }
                  }
                }

                span:first-child {
                  width: 40%;
                }

                span:last-child {
                  width: 60%;
                }
              }

              hr {
                width: 100%;
                height: 1px;
                border: none;
                background: rgba(255, 255, 255, 0.3);
                margin: 3px 0;
              }
            }
          }

          .spinner {
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
            width: 50px;
            height: 50px;

            & .path {
              stroke: hsl(210, 70, 75);
              stroke-linecap: round;
              animation: dash 1.5s ease-in-out infinite;
            }
          }
        }
      }
    }
  }
}

//MEDIA STYLES

@media screen and (max-width: 991px) {
  .bank_cards {
    margin-bottom: 30px;

    .bank_cards_inner {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;

      .bank_card {

        .bank_card_inner {
          width: 100%;
          background: rgba(0, 0, 0, 0.7);
          border: 1px solid #fff;
          padding: 10px;
          color: #fff;
          overflow: hidden;
          height: 100px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 5px 5px 0 0;
          font-size: 12px;

          .d-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .divka {
            display: flex;
            width: 100%;
          }

          .bank_card_default {
            text-transform: uppercase;
            font-weight: 800;
          }

          &.disabled {
            .bank_card_default {
              opacity: 0;
            }
          }

          .bank_card_number {
            margin-right: 20px;
          }

          .bank_card_holder {
            text-transform: uppercase;
          }

          .bank_card_expire {}

          .bank_card_type {
            width: 100px;
            display: flex;
            justify-content: flex-end;

            &.visa {
              img {
                max-width: 100%;
              }
            }

            &.mastercard {
              img {
                max-width: 100%;
                max-height: 40px;
              }
            }
          }
        }
      }
    }
  }

  .balance_page {
    .balance_page_inner {
      padding-top: 40px;
      padding-bottom: 50px;
      overflow-y: scroll;

      .custom_container {
        .balance_history_outer {
          .up_balance {
            .flex_outer {
              justify-content: space-between;
              width: 100%;

              .flex_inner {
                flex-direction: column;
                margin-right: 20px;

                input {
                  border-bottom-left-radius: 0;
                }

                button[type="submit"] {
                  border-left: 1px solid #fff;
                  border-top: none;
                  border-top-right-radius: 0;
                }
              }

              .btns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;

                button {
                  background: rgba(0, 0, 0, 0.5);
                  border-radius: 5px;
                  color: #fff;
                  border: 1px solid #fff;
                  font-size: 1rem;
                  font-weight: 400;
                  outline: none;
                  padding: 4px 0;
                  width: 50px;
                  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);

                  &:disabled {
                    background-color: transparent;
                    color: gray;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .balance_page {
    .balance_page_inner {

      .balance_history_outer {
        .block_title {
          h3 {
            font-size: 1.6rem;
            padding-right: 35px;

            .close_page {
              top: -5px;
            }
          }
        }

        .up_balance {
          display: flex;
          flex-direction: column;
          width: 100%;

          .input_outer {
            width: 100%;

            input {
              width: 100%;
              margin-right: 0;
              border-radius: 5px 5px 0 0;
            }

            .currency {
              bottom: auto;
              height: 40px;
              right: 0;
            }
          }


          button {
            width: 100%;
            margin-left: 0;
            border-top: none;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            border-radius: 0 0 5px 5px;
          }

          .payment_infobar {
            width: 100%;
            margin-top: 10px;

            span {
              font-size: 16px;
            }
          }
        }

        .balance_history_table {

          .table_mobile {
            margin-bottom: 20px;
            min-height: 200px;
            max-height: 330px;
            overflow: scroll;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .balance_page {
    .balance_page_inner {
      .balance_history_outer {
        .balance_history_table {
          .table_mobile {
            margin-bottom: 20px;
            min-height: 200px;
            max-height: 220px;
            overflow: scroll;

            .table_mobile_inner {
              .item_row {
                span {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
