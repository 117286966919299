import en from './en.json'
import ru from './ru.json'
import bg from './bg.json'
import kz from './kz.json'

export const defaultLocale = 'en'

export const languages = {
    en: en,
    ru: ru,
    kz: kz,
    bg: bg
}
