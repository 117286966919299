export default ({
    setRfidStatus(state, payload) {
        state.rfidStatus = payload
    },
    setReconnectSocket(state, payload) {
        state.reconnectSocket = payload
    },
    clearOnlySocketData(state) {
        state.socketData = null
    },
    clearSocketData(state) {
        state.socketData = null
        state.socketFinishedData = null
    },
    setSocketFinishedData(state, payload) {
        state.socketFinishedData = payload
    },
    setSocketData(state, payload) {
      state.socketData = payload
    },
    setLegend(state, payload) {
        state.legend = payload
    },
    setMobileMenuStatus(state, payload) {
        state.MobileMenuStatus = payload
    },
    setSessionModalData(state, payload) {
        state.SessionModalData = payload
    },
    setGeoFound(state, payload) {
        state.GeoFound = payload
    },
    setIsProd(state, payload) {
        state.isProd = payload
    },
    setUserGeoposition(state, payload) {
        state.UserGeoposition = payload
    },
    setRealtimeUserPosition(state, payload) {
        state.RealtimeUserPosition = payload
    },
    setStations(state, payload) {
        state.Stations = payload
        if (state.SessionModalData !== null) {
            let currentStation = state.SessionModalData.Key
            state.SessionModalData = payload.find(payload => payload.Key === currentStation)
        }
    },
    setAccountInfo(state, payload) {
        state.AccountInfo = payload
    },
    setAuthStatus(state, payload) {
        state.AuthStatus = payload
    },
    setAuthModal(state, payload) {
        state.AuthModal = payload
    },
    setMainPage(state) {
        state.MainPage = true
        state.BalancePage = false
        state.HistoryPage = false
        state.RfidPage = false
        state.ProfilePage = false
        state.ListPage = false
        state.FavoritesPage = false
    },
    setBalancePage(state) {
        state.MainPage = false
        state.BalancePage = true
        state.HistoryPage = false
        state.RfidPage = false
        state.ProfilePage = false
        state.ListPage = false
        state.FavoritesPage = false
    },
    setHistoryPage(state) {
        state.MainPage = false
        state.BalancePage = false
        state.HistoryPage = true
        state.RfidPage = false
        state.ProfilePage = false
        state.ListPage = false
        state.FavoritesPage = false
    },
    setRfidPage(state) {
        state.MainPage = false
        state.BalancePage = false
        state.HistoryPage = false
        state.RfidPage = true
        state.ProfilePage = false
        state.ListPage = false
        state.FavoritesPage = false
    },
    setProfilePage(state) {
        state.MainPage = false
        state.BalancePage = false
        state.HistoryPage = false
        state.RfidPage = false
        state.ProfilePage = true
        state.ListPage = false
        state.FavoritesPage = false
    },
    setListPage(state) {
        state.MainPage = false
        state.BalancePage = false
        state.HistoryPage = false
        state.RfidPage = false
        state.ProfilePage = false
        state.ListPage = true
        state.FavoritesPage = false
    },
    setFavoritesPage(state) {
        state.MainPage = false
        state.BalancePage = false
        state.HistoryPage = false
        state.RfidPage = false
        state.ProfilePage = false
        state.ListPage = false
        state.FavoritesPage = true
    },
    setMapReadyStatus(state, payload) {
        state.mapReady = payload
    },
    setAuthChecked(state, payload) {
        state.authChecked = payload
    },
    setDCmode(state, payload) {
        state.DCmode = payload
    }
})
