<template>
  <div class="list_page">
    <div v-touch:swipe.left="FavActive" v-touch:swipe.right="MainPageActive" class="spot_list_inner">
      <div class="custom_container">
        <div class="spots_list_inner">
          <h3>{{ $t("spots_list_page.page_title") }}
            <div class="close_page">
              <a @click.prevent="MainPageActive" href="">
                <svg height="15px" viewBox="0 0 311 311.07733" width="15px"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill="#fff"
                        d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"/>
                  <path fill="#fff"
                        d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"/>
                </svg>
              </a>
            </div>
          </h3>
          <div class="table">
            <div class="table_head_row">
              <div class="table_head_col h1">№</div>
              <div class="table_head_col h2">{{ $t("spots_list_page.spot_name") }}</div>
              <div class="table_head_col h3">{{ $t("spots_list_page.spot_distance") }}</div>
              <div class="table_head_col h4">{{ $t("spots_list_page.spot_work_time") }}</div>
              <div class="table_head_col h5">{{ $t("spots_list_page.spot_status") }}</div>
              <div :class="{hidden : !getAuthStatus}" class="table_head_col h6"></div>
            </div>
            <!-- Таблица для десктопа -->
            <transition name="fade">
              <div v-if="loading === false && isMobile === false" class="table_body">
                <div v-for="(item, index) in paginatedData" :key="item.Key" class="table_body_row">
                  <div @click="goToSpot(item)" class="table_body_col b1">
                    {{ ((index + 1) + (pageNumber * 10)) }}
                  </div>
                  <div @click="goToSpot(item)" class="table_body_col b2">
                    {{ item.NetworkCity }}, {{ item.Title.replace("amp;", "") }}
                  </div>
                  <div v-if="getGeoFound" @click="goToSpot(item)" class="table_body_col b3">
                    {{ getDistance(item.Distance) }} {{ $t("km") }}
                  </div>
                  <div v-else @click="goToSpot(item)" class="table_body_col b3">
                    -
                  </div>
                  <div @click="goToSpot(item)" class="table_body_col b4">
                    {{ item.Hours ? item.Hours : '-' }}
                  </div>
                  <div @click="goToSpot(item)" style="color: gray" v-if="item.StatusId == 1" class="table_body_col b5">
                    SOON
                  </div>
                  <div @click="goToSpot(item)" v-else-if="item.Free <= item.Amount && item.Amount > 0 && item.Free != 0" class="table_body_col b5">
                    {{ item.Free }}/{{ item.Amount }}
                  </div>
                  <div @click="goToSpot(item)" style="color: red" v-else-if="item.Free == 0 && item.Amount > 0" class="table_body_col b5">
                    BUSY
                  </div>
                  <div @click="goToSpot(item)" v-else style="color: red" class="table_body_col b5">
                    OFFLINE
                  </div>
                  <div v-if="getAuthStatus" class="table_body_col b6" @click="toggleFavorite(item.Bookmark, item.Key)">
                    <svg version="1.1" :class="{favorited : item.Bookmark > 0}" height="20" id="Capa_1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 485 485" style="enable-background:new 0 0 485 485;" xml:space="preserve">
                      <path fill="#fff" d="M343.611,22.543c-22.613,0-44.227,5.184-64.238,15.409c-13.622,6.959-26.136,16.205-36.873,27.175
                        c-10.738-10.97-23.251-20.216-36.873-27.175c-20.012-10.225-41.625-15.409-64.239-15.409C63.427,22.543,0,85.97,0,163.932
                        c0,55.219,29.163,113.866,86.678,174.314c48.022,50.471,106.816,92.543,147.681,118.95l8.141,5.261l8.141-5.261
                        c40.865-26.406,99.659-68.479,147.682-118.95C455.838,277.798,485,219.151,485,163.932C485,85.97,421.573,22.543,343.611,22.543z
                         M376.589,317.566c-42.918,45.106-95.196,83.452-134.089,109.116c-38.893-25.665-91.171-64.01-134.088-109.116
                        C56.381,262.884,30,211.194,30,163.932c0-61.42,49.969-111.389,111.389-111.389c35.361,0,67.844,16.243,89.118,44.563
                        l11.993,15.965l11.993-15.965c21.274-28.32,53.757-44.563,89.118-44.563c61.42,0,111.389,49.969,111.389,111.389
                        C455,211.194,428.618,262.884,376.589,317.566z"/>
                </svg>
                  </div>
                </div>
              </div>
            </transition>
            <!-- Таблица для мобилы -->
            <transition name="fade">
              <div v-if="loading === false && isMobile === true" class="table_body mobile_table_body">
                <div v-for="(item, index) in spotsList" :key="item.Key" class="table_body_row">
                  <div @click="goToSpot(item)" class="table_body_col b1">
                    {{ index + 1 }}
                  </div>
                  <div @click="goToSpot(item)" class="table_body_col b2">
                    {{ item.NetworkCity }}, {{ item.Title.replace("amp;", "") }}
                  </div>
                  <div v-if="getGeoFound" @click="goToSpot(item)" class="table_body_col b3">
                    {{ getDistance(item.Distance) }} {{ $t("km") }}
                  </div>
                  <div v-else @click="goToSpot(item)" class="table_body_col b3">
                    -
                  </div>
                  <div @click="goToSpot(item)" class="table_body_col b4">
                    {{ item.Hours ? item.Hours : '-' }}
                  </div>
                  <div @click="goToSpot(item)" style="color: gray" v-if="item.StatusId == 1" class="table_body_col b5">
                    SOON
                  </div>
                  <div @click="goToSpot(item)" v-else-if="item.Free <= item.Amount && item.Amount > 0 && item.Free != 0" class="table_body_col b5">
                    {{ item.Free }}/{{ item.Amount }}
                  </div>
                  <div @click="goToSpot(item)" style="color: red" v-else-if="item.Free == 0 && item.Amount > 0" class="table_body_col b5">
                    BUSY
                  </div>
                  <div @click="goToSpot(item)" v-else style="color: red" class="table_body_col b5">
                    OFFLINE
                  </div>
                  <div v-if="getAuthStatus" class="table_body_col b6" @click="toggleFavorite(item.Bookmark, item.Key)">
                    <svg version="1.1" :class="{favorited : item.Bookmark > 0}" height="20" id="Capa_1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 485 485" style="enable-background:new 0 0 485 485;" xml:space="preserve">
                      <path fill="#fff" d="M343.611,22.543c-22.613,0-44.227,5.184-64.238,15.409c-13.622,6.959-26.136,16.205-36.873,27.175
                        c-10.738-10.97-23.251-20.216-36.873-27.175c-20.012-10.225-41.625-15.409-64.239-15.409C63.427,22.543,0,85.97,0,163.932
                        c0,55.219,29.163,113.866,86.678,174.314c48.022,50.471,106.816,92.543,147.681,118.95l8.141,5.261l8.141-5.261
                        c40.865-26.406,99.659-68.479,147.682-118.95C455.838,277.798,485,219.151,485,163.932C485,85.97,421.573,22.543,343.611,22.543z
                         M376.589,317.566c-42.918,45.106-95.196,83.452-134.089,109.116c-38.893-25.665-91.171-64.01-134.088-109.116
                        C56.381,262.884,30,211.194,30,163.932c0-61.42,49.969-111.389,111.389-111.389c35.361,0,67.844,16.243,89.118,44.563
                        l11.993,15.965l11.993-15.965c21.274-28.32,53.757-44.563,89.118-44.563c61.42,0,111.389,49.969,111.389,111.389
                        C455,211.194,428.618,262.884,376.589,317.566z"/>
                </svg>
                  </div>
                </div>
              </div>
            </transition>
            <img v-if="loading === true" src="/images/new_spinner.gif" alt="" class="spinner">
          </div>
        </div>
      </div>
      <div v-show="isMobile === false && pageCount > 1" class="pagination">
        <button class="prev" :disabled="pageNumber === 0" @click="prevPage">&lt;</button>
        <span>{{ pageNumber + 1 }} / {{ pageCount }}</span>
        <button class="next" :disabled="pageNumber >= pageCount -1" @click="nextPage">&gt;</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex"

export default {
  name: "ListPage",
  data: function () {
    return {
      spotsList: [],
      loading: false,
      pageNumber: 0,
      size: 10,
    }
  },
  methods: {
    FavActive() {
      if (this.$store.getters.getAuthStatus === true) {
        this.$store.commit('setFavoritesPage')
      }
    },
    getDistance(distance) {
      return Math.round(distance)
    },
    nextPage() {
      this.pageNumber++
    },
    prevPage() {
      this.pageNumber--
    },
    toggleFavorite(Bookmark, Key) {
      let _this = this
      this.loading = true
      if (Bookmark <= 0) {
        _this.$store.dispatch('setFavoriteSpot', Key).then(() => {
          refreshSpotsList()
        })
      } else {
        _this.$store.dispatch('removeFavoriteSpot', Key).then(() => {
          refreshSpotsList()
        })
      }

      function refreshSpotsList() {
        if (_this.$store.getters.getGeoFound) {
          let lng = _this.$store.getters.getUserGeoposition.lng
          let lat = _this.$store.getters.getUserGeoposition.lat
          let spotsUrl = '';
          let current_lang = localStorage.getItem('SessionLang')
          if(_this.$store.getters.getIsProd) {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&o_soon=0&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&f_token=' + localStorage.getItem('AuthToken') + '&lang=' + current_lang
          } else {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&f_token=' + localStorage.getItem('AuthToken') + '&lang=' + current_lang
          }

          axios({
            url: 'https://voltspot.net/url/cors.php',
            method: 'POST',
            params: {
              url: spotsUrl
            }
          })
              .then(resp => {
                if (resp.data.rcode >= 0) {
                  _this.spotsList = resp.data.rdata.Records
                  if (_this.$store.getters.getDCmode) {
                    _this.spotsList = _this.spotsList.filter(obj => obj.BespsLocationType === 'dc');
                  }
                } else {
                  console.log('Ошибка получения списка станций: ' + resp.data.rtext)
                }
                _this.loading = false
              })
        } else {
          let spotsUrl = '';
          let current_lang = localStorage.getItem('SessionLang')
          if(_this.$store.getters.getIsProd) {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?format=json' + '&o_soon=0&f_token=' + localStorage.getItem('AuthToken') + '&lang=' + current_lang
          } else {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?format=json' + '&f_token=' + localStorage.getItem('AuthToken') + '&lang=' + current_lang
          }

          axios({
            url: 'https://voltspot.net/url/cors.php',
            method: 'POST',
            params: {
              url: spotsUrl
            }
          })
              .then(resp => {
                if (resp.data.rcode >= 0) {
                  _this.spotsList = resp.data.rdata.Records
                  if (_this.$store.getters.getDCmode) {
                    _this.spotsList = _this.spotsList.filter(obj => obj.BespsLocationType === 'dc');
                  }
                } else {
                  console.log('Ошибка получения списка станций: ' + resp.data.rtext)
                }
                _this.loading = false
              })
        }
      }
    },
    goToSpot(item) {
      this.$store.commit('setMainPage')
      this.$parent.$emit('flyToSpot', item)
    },
    MainPageActive() {
      this.$store.commit('setMainPage')
    }
  },
  computed: {
    ...mapGetters(['getAuthStatus', 'getGeoFound']),
    pageCount() {
      let l = this.spotsList.length,
          s = this.size
      return Math.ceil(l / s)
    },
    paginatedData() {
      const start = this.pageNumber * this.size
      const end = start + this.size
      return this.spotsList.slice(start, end)
    },
    isMobile() {
      return window.innerWidth <= 575
    }
  },
  mounted() {
    let browser = window.navigator.userAgent.substr(-40,)
    let firefox = browser.indexOf('Firefox')
    let mi = browser.indexOf('MiuiBrowser')
    if (firefox >= 0 || mi >= 0) {
      document.querySelector('.spot_list_inner').style.background = 'rgba(0, 0, 0, 0.8)'
    }

    let _this = this
    this.loading = true
    if (_this.$store.getters.getGeoFound) {
      let lng = _this.$store.getters.getUserGeoposition.lng
      let lat = _this.$store.getters.getUserGeoposition.lat
      let spotsUrl = '';
      let current_lang = localStorage.getItem('SessionLang')
          if(_this.$store.getters.getIsProd) {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&o_soon=0&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&lang=' + current_lang
          } else {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&lang=' + current_lang
          }

      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: spotsUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.spotsList = resp.data.rdata.Records
              if (_this.$store.getters.getDCmode) {
                    _this.spotsList = _this.spotsList.filter(obj => obj.BespsLocationType === 'dc');
                  }
            } else {
              console.log('Ошибка получения списка станций: ' + resp.data.rtext)
            }
            _this.loading = false
          })
    } else {
      let spotsUrl = '';
      let current_lang = localStorage.getItem('SessionLang')
          if(_this.$store.getters.getIsProd) {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&o_soon=0&format=json' + '&lang=' + current_lang
          } else {
            spotsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&format=json' + '&lang=' + current_lang
          }
      
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: spotsUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.spotsList = resp.data.rdata.Records
              if (_this.$store.getters.getDCmode) {
                    _this.spotsList = _this.spotsList.filter(obj => obj.BespsLocationType === 'dc');
                  }
            } else {
              console.log('Ошибка получения списка станций: ' + resp.data.rtext)
            }
            _this.loading = false
          })
    }
  },
  created() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }
}
</script>

<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.hidden {
  display: none !important;
}

.list_page {
  .spot_list_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100 - 90px);
    margin-top: 40px;
    padding-top: 80px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
    z-index: 402;

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 100%;

      span {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 1.2rem;
        line-height: 1;
        color: #fff;
        width: 100px;
        height: 28px;
        align-items: center;
        flex-wrap: nowrap;
        display: flex;
        justify-content: center;
        user-select: none;
        text-align: center;
      }

      button {
        outline: none;
        display: flex;
        border: 1px solid #fff;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1;
        transition: all .2s linear;
        user-select: none;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

        &.prev {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        &.next {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }

        &:disabled {
          transition: all .2s linear;
          border: 1px solid #fff;
          background: transparent;
        }
      }
    }

    .spots_list_inner {
      h3 {
        color: #fff;
        font-size: 2.2rem;
        font-weight: 400;
        position: relative;
        line-height: 1;

        .close_page {
          position: absolute;
          top: 0;
          right: 0;

          a {
            background: transparent;
            font-weight: 400;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            border: 1px solid #fff;
            border-radius: 5px;
            text-decoration: none;
            outline: none;
            box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.4);
            transition: all .2s linear;

            &:hover {
              transition: all .2s linear;
              box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.8);
              background: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }

      .table {
        display: flex;
        min-height: 300px;
        width: 100%;
        flex-direction: column;
        margin: 30px 0;
        position: relative;

        .table_head_row {
          display: flex;
          justify-content: space-between;

          .table_head_col {
            user-select: none;
            cursor: pointer;
            color: #fff;
            font-size: 1rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &.h1 {
              width: 5%;
              justify-content: center;
            }

            &.h2 {
              width: 35%;
            }

            &.h3 {
              width: 20%;
            }

            &.h4 {
              width: 20%;
            }

            &.h5 {
              width: 15%;
            }

            &.h6 {
              width: 5%;
              justify-content: center;
            }
          }
        }

        .table_body {
          display: flex;
          width: 100%;
          flex-direction: column;
          min-height: 350px;

          .table_body_row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            background: rgba(0, 0, 0, 0.5);
            margin: 3px 0;
            border: 1px solid #000;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

            &:first-child {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            &:last-child {
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }

            .table_body_col {
              cursor: pointer;
              user-select: none;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              color: #fff;
              font-size: 1rem;
              overflow: hidden;
              font-weight: 400;
              line-height: 1.5;
              white-space: nowrap;

              &.b1 {
                width: 5%;
                justify-content: center;
              }

              &.b2 {
                width: 35%;
              }

              &.b3 {
                width: 20%;
              }

              &.b4 {
                width: 20%;
              }

              &.b5 {
                width: 15%;
              }

              &.b6 {
                width: 5%;
                justify-content: center;

                svg {
                  transition: all .2s linear;

                  &:hover {
                    path {
                      fill: red;
                      transition: all .2s linear;
                    }
                  }

                  &.favorited {
                    path {
                      fill: red;
                      transition: all .2s linear;
                    }
                  }
                }
              }
            }
          }

          &.mobile_table_body {
            height: 370px;
            overflow: hidden;
            overflow-y: scroll;
          }
        }

        .spinner {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -25px 0 0 -25px;
          width: 50px;
          height: 50px;

          & .path {
            stroke: hsl(210, 70, 75);
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
          }

        }
      }
    }
  }
}

//MEDIA STYLES

@media screen and (max-width: 991px) {
  .list_page {
    .spot_list_inner {
      padding-top: 40px;
      overflow-y: scroll;

      .spots_list_inner {
        .table {
          .table_body {
            .table_body_row {

              .table_body_col {
                font-size: 0.8rem;

                svg {
                  height: 15px;
                }
              }

              .table_body_col.b1 {
                width: 5%;
              }

              .table_body_col.b2 {
                width: 45%;
                overflow: hidden;
              }

              .table_body_col.b3 {
                display: none;
              }

              .table_body_col.b4 {
                width: 20%;
              }

              .table_body_col.b5 {
                width: 25%;
              }

              .table_body_col.b6 {
                width: 5%;
              }
            }
          }

          .table_head_row {

            .table_head_col {
              font-size: 0.8rem;
            }

            .table_head_col.h1 {
              width: 5%;
            }

            .table_head_col.h2 {
              width: 45%;
            }

            .table_head_col.h3 {
              display: none;
            }

            .table_head_col.h4 {
              width: 20%;
            }

            .table_head_col.h5 {
              width: 25%;
            }

            .table_head_col.h6 {
              width: 5%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .list_page {
    .spot_list_inner {

      .spots_list_inner {
        h3 {
          font-size: 1.6rem;

          .close_page {
            top: -5px;
          }
        }

        .table {
          margin-bottom: 20px;
          min-height: 400px;

          .table_body {
            min-height: 200px;

            .table_body_row {
              min-height: 40px;

              .table_body_col {
                line-height: 3;
              }

              .table_body_col.b1 {
                width: 10%;
              }

              .table_body_col.b2 {
                width: 60%;
              }

              .table_body_col.b3 {
                display: none;
              }

              .table_body_col.b4 {
                display: none;
              }

              .table_body_col.b5 {
                width: 20%;
                justify-content: center;
                text-align: center;
              }

              .table_body_col.b6 {
                width: 10%;
              }
            }
          }

          .table_head_row {
            .table_head_col {
              font-size: 0.75rem;
            }

            .table_head_col.h1 {
              width: 10%;
            }

            .table_head_col.h2 {
              width: 60%;
            }

            .table_head_col.h3 {
              display: none;
            }

            .table_head_col.h4 {
              display: none;
            }

            .table_head_col.h5 {
              width: 20%;
              justify-content: center;
              text-align: center;
            }

            .table_head_col.h6 {
              width: 10%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .list_page {
    .spot_list_inner {
      .spots_list_inner {
        .table {
          margin-bottom: 0;
          min-height: 320px;

          .table_body {
            &.mobile_table_body {
              height: 290px;
              overflow: hidden;
              overflow-y: scroll;
            }

            .table_body_row {
              .table_body_col {
                line-height: 2.5;
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}

</style>
