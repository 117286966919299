import axios from 'axios'
import Vue from 'vue'

export default ({
    setIsProdStatus: ({commit}) => {
        window.location.host === 'my.voltspot.net' ? commit('setIsProd', true) : commit('setIsProd', false);
    },
    gotGeoPosition: ({commit, dispatch}) => {
        let startPos
        let geoOptions = {
            enableHighAccuracy: true
        };
        let geoSuccess = function (position) {
            startPos = position
            let lat = startPos.coords.latitude
            let lng = startPos.coords.longitude
            let geoLatLng = {lat, lng}
            commit('setGeoFound', true)
            commit('setUserGeoposition', geoLatLng)
            dispatch('gotStations', true)
        };
        let geoError = function (error) {
            console.log('Ошибка определения местоположения. Код ошибки: ' + error.code)
            commit('setGeoFound', false)
            dispatch('gotStations', false)
        };
        navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
    },
    realtimeUserPosition: ({commit}) => {
        let startPos
        let geoOptions = {
            enableHighAccuracy: true
        };
        let geoSuccess = function (position) {
            startPos = position
            let lat = startPos.coords.latitude
            let lng = startPos.coords.longitude
            let geoLatLng = {lat, lng}
            commit('setRealtimeUserPosition', geoLatLng)
        };
        let geoError = function (error) {
            console.log('Ошибка определения местоположения пользователя. Код ошибки: ' + error.code)
            commit('setRealtimeUserPosition', false)
        };

        setTimeout(check_geo_func, 2000);

        setInterval(check_geo_func, 10000);
          
        function check_geo_func() {
            navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
          }
    },
    initSocket: ({commit, dispatch}) => {
            let hSocket = new WebSocket( "wss://api.voltspot.net:8010" )
            hSocket.onopen = function() {
                hSocket.send("&token=" + localStorage.getItem('AuthToken'))
                commit('setReconnectSocket', false)
            };
            hSocket.onmessage = function(event) {
                commit('setReconnectSocket', false)
                let event_data = JSON.parse( event.data )
                console.log(event_data)
                if (event_data.Class === 'api_besp_session' && event_data.Event === 'activated') {
                    commit('setSocketData', event_data)
                    dispatch('gotAccountInfo')
                }
                else if (event_data.Class === 'api_besp_nfc_status') {
                    if (event_data.Data['Code'] == '-1') {
                        Vue.notify({
                            type: 'error',
                            text: event_data.Data['Message']
                        })
                        commit('setRfidStatus', 0)
                    }
                    else if (event_data.Data['Code'] == 1) {
                        Vue.notify({
                            type: 'info',
                            text: event_data.Data['Message']
                        })
                        commit('setRfidStatus', 1)
                    }
                    else if (event_data.Data['Code'] == 2) {
                        commit('setRfidStatus', 2)
                    }
                }
                // else if (event_data.Class === 'api_besp_nfc_ok') {
                //     Vue.notify({
                //         type: 'success',
                //         text: 'Карта успешно привязана'
                //     })
                // }
                // else if (event_data.Class === 'api_besp_nfc_bad_card') {
                //     Vue.notify({
                //         type: 'error',
                //         text: 'Карта не подходит'
                //     })
                // }
                // else if (event_data.Class === 'api_besp_nfc_failed') {
                //     Vue.notify({
                //         type: 'error',
                //         text: 'Не удалось привязать карту'
                //     })
                // }
                else if (event_data.Class === 'api_besp_session' && event_data.Event === 'started') {
                    commit('setSocketData', event_data)
                    dispatch('gotAccountInfo')
                }
                else if (event_data.Class === 'api_besp_session' && event_data.Event === 'tick') {
                    commit('setSocketData', event_data)
                    dispatch('gotAccountInfo')
                }
                else if (event_data.Class === 'api_besp_session' && event_data.Event === 'pause') {
                    commit('setSocketData', event_data)
                    dispatch('gotAccountInfo')
                }
                else if (event_data.Class === 'api_besp_session' && event_data.Event === 'canceled') {
                    dispatch('gotAccountInfo')
                    commit('setSocketData', event_data)
                    setTimeout(() => commit('clearSocketData'), 3000)
                }
                else if (event_data.Class === 'api_besp_session' && event_data.Event === 'finished') {
                    commit('setSocketFinishedData', event_data)
                    dispatch('gotAccountInfo')
                }
                else if (event_data.Class === 'api_besp_location') {
                    dispatch('gotStations')
                    dispatch('gotAccountInfo')
                }
                else if (event_data.Class === 'api_besp_balance') {
                    dispatch('gotAccountInfo')
                }
            };
            hSocket.onerror = function(error) {
                console.log( error)
                commit('setReconnectSocket', true)
                setTimeout(function() {
                    dispatch('initSocket');
                }, 1000);
            };
            hSocket.onclose = function(event) {
                console.log( event )
                commit('setReconnectSocket', true)
                setTimeout(function() {
                    dispatch('initSocket');
                }, 1000);
            };
    },
    setSessionLang: () => {
        if (localStorage.getItem('SessionLang') === null) {
            let language = window.navigator ? (window.navigator.language ||
                window.navigator.systemLanguage ||
                window.navigator.userLanguage) : "en";
            language = language.substr(0, 2).toLowerCase();
            if (language === 'ru') {
                language = 'ru'
            } else if (language === 'bg') {
                language = 'bg'
            } else if (language === 'kz') {
                language = 'kz'
            } else {
                language = 'en'
            }
            localStorage.setItem('SessionLang', language);
        }
    },
    gotStations: ({commit, getters}, boolean) => {
        if (boolean === true || getters.getGeoFound === true) {
            let lat = getters.getUserGeoposition.lat
            let lng = getters.getUserGeoposition.lng
            let token = localStorage.getItem('AuthToken');
            let current_lang = localStorage.getItem('SessionLang');
            let stationsUrl = '';
            if(getters.getIsProd) {
                if (token === null) {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?o_soon=0&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&lang=' + current_lang;
                } else {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?o_soon=0&f_token=' + localStorage.getItem('AuthToken') + '&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&lang=' + current_lang;
                }
            } else {
                if (token === null) {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&lang=' + current_lang;
                } else {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&s_corlat=' + lat + '&s_corlng=' + lng + '&format=json' + '&lang=' + current_lang;
                }
            }
            axios({
                url: 'https://voltspot.net/url/cors.php',
                method: 'POST',
                params: {
                    url: stationsUrl
                }
            })
                .then(resp => {
                    if (resp.data.rcode > 0) {
                        let stations = resp.data.rdata.Records;
                        if (getters.getDCmode) {
                            stations = stations.filter(obj => obj.BespsLocationType === 'dc');
                        }
                        commit('setStations', stations);
                    } else {
                        console.log('Ошибка получения списка станций: ' + resp.data.rtext)
                    }
                })
                .catch()
        } else {
            let token = localStorage.getItem('AuthToken')
            let current_lang = localStorage.getItem('SessionLang')
            let stationsUrl = '';
            if(getters.getIsProd) {
                if (token === null) {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?o_soon=0&format=json' + '&lang=' + current_lang;
                } else {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?o_soon=0&f_token=' + localStorage.getItem('AuthToken') + '&format=json' + '&lang=' + current_lang;
                }
            } else {
                if (token === null) {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?format=json' + '&lang=' + current_lang;
                } else {
                    stationsUrl = process.env.VUE_APP_API_URL + '/app/besp/location/records/?f_token=' + localStorage.getItem('AuthToken') + '&format=json' + '&lang=' + current_lang;
                }
            }
            axios({
                url: 'https://voltspot.net/url/cors.php',
                method: 'POST',
                params: {
                    url: stationsUrl
                }
            })
                .then(resp => {
                    if (resp.data.rcode > 0) {
                        let stations = resp.data.rdata.Records;
                        if (getters.getDCmode) {
                            stations = stations.filter(obj => obj.BespsLocationType === 'dc');
                        }
                        commit('setStations', stations);
                    } else {
                        console.log('Ошибка получения списка станций: ' + resp.data.rtext)
                    }
                })
                .catch()
        }
    },
    gotAccountInfo: ({commit, getters}) => {
        let get_msg_lob_balance = function() {
            let cookLang = localStorage.getItem('SessionLang')
            if (cookLang === 'ru') {
                return 'Ваш баланс составляет менее 1 BGN'
            } else if (cookLang === 'en') {
                return 'Your balance is less than 1 BGN'
            } else if (cookLang === 'kz') {
                return 'Sızdıñ balansyñyz 1 BGN az'
            } else {
                return 'Балансът ви е под 1 лв'
            }
        };


        let current_lang = localStorage.getItem('SessionLang')
        let token = localStorage.getItem('AuthToken')
        if (token) {
            let sessionUrl = process.env.VUE_APP_API_URL + '/app/besp/account/session/?format=json&f_token=' + token + '&lang=' + current_lang
            axios({
                url: 'https://voltspot.net/url/cors.php',
                method: 'POST',
                params: {
                    url: sessionUrl
                }
            })
                .then(resp => {
                    if (resp.data.rcode > 0) {
                        commit('setAccountInfo', resp.data.rdata)
                        commit('setAuthStatus', true)
                        if (getters.getAccountInfo.Balance < 1 && getters.getMainPage) {
                            commit('setBalancePage')
                            Vue.notify({
                                type: 'warn',
                                text: get_msg_lob_balance()
                            });
                        }
                    } else {
                        if (resp.data.rcode <= 0) {
                            localStorage.removeItem('AuthToken')
                            commit('setAuthStatus', false)
                        }
                    }
                    commit('setAuthChecked', true)
                })
                .catch();
        }
        // dispatch('gotStations')
    },
    setFavoriteSpot: async ({dispatch, state}, key) => {
        let setFavoriteUrl = process.env.VUE_APP_API_URL + '/app/besp/bookmark/add/?f_token=' + localStorage.getItem('AuthToken') + '&f_location_id=' + key + '&format=json'
        await axios({
            url: 'https://voltspot.net/url/cors.php',
            method: 'POST',
            params: {
                url: setFavoriteUrl
            }
        })
            .then(resp => {
                if (resp.data.rcode > 0) {
                    dispatch('gotAccountInfo')
                    state.SessionModalData.Bookmark = 1
                } else {
                    console.log(resp.data.rtext)
                }
            })
            .catch(err => {
                console.log('Ошибка запроса добавления точки в Избранные: ' + err);
            })
    },
    removeFavoriteSpot: async ({dispatch, state}, key) => {
        let removeFavoriteUrl = process.env.VUE_APP_API_URL + '/app/besp/bookmark/del/?f_token=' + localStorage.getItem('AuthToken') + '&f_location_id=' + key + '&format=json'
        await axios({
            url: 'https://voltspot.net/url/cors.php',
            method: 'POST',
            params: {
                url: removeFavoriteUrl
            }
        })
            .then(resp => {
                if (resp.data.rcode > 0) {
                    dispatch('gotAccountInfo')
                    state.SessionModalData.Bookmark = 0
                } else {
                    console.log(resp.data.rcode.rtext)
                }
            })
            .catch(err => {
                console.log('Ошибка запроса удаления точки с Избранных: ' + err);
            })
    },
})
