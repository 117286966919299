<template>
  <div @click="setLegend(false)" class="top_board">
    <div class="top">
      <div class="left_block">
        <!--MobileNav-->
        <div v-if="!getMobileMenuStatus" @click="getAuthStatus ? openMobileMenu() : setAuthModal(true)"
          class="mobile_nav_icon">
          <div class="hamburger_icon"></div>
        </div>
        <a @click.prevent="setMainPage" href="" class="logo">
          <img class="desktop_logo" src="/images/logo_new.png" alt="VoltSpot">
        </a>
        <ul class="top_menu">
          <li v-if="getAuthStatus"><a :class="{ active: getBalancePage }" @click.prevent="setBalancePage" href="">{{
            $t("payments")
              }}</a>
          </li>
          <li v-else><a @click.prevent="setAuthModal(true)" href="">{{ $t("message.top_menu_balance") }}</a></li>
          <!-- <li v-if="getAuthStatus"><a :class="{active: getRfidPage}" @click.prevent="setRfidPage" href="">RFID</a></li> -->
          <li v-if="getAuthStatus"><a :class="{ active: getHistoryPage }" @click.prevent="setHistoryPage" href="">{{
            $t("message.top_menu_history")
              }}</a>
          </li>
          <li v-else><a @click.prevent="setAuthModal(true)" href="">{{ $t("message.top_menu_history") }}</a></li>
          <li v-if="getAuthStatus"><a :class="{ active: getProfilePage }" @click.prevent="setProfilePage" href="">{{
            $t("message.top_menu_my_profile")
              }}</a>
          </li>
          <li v-else><a @click.prevent="setAuthModal(true)" href="">{{ $t("message.top_menu_my_profile") }}</a></li>
        </ul>
      </div>
      <!-- <div v-if="getIsMobile" @click="toggleZoom()" class="loupe">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
          <path
            d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
        </svg>
        <span>x{{ zoom }}</span>
      </div> -->
      <div class="right_block">
        <hr v-if="!getIsMobile">
        <div v-if="getAuthStatus" class="balance_block">
          <a href="" @click.prevent="setBalancePage">
            <svg fill="#62A321" height="30" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g>
                <path d="M438.154,44.308H73.846C33.127,44.308,0,77.435,0,118.154v275.692c0,40.719,33.127,73.846,73.846,73.846h364.308
                c40.719,0,73.846-33.127,73.846-73.846V118.154C512,77.435,478.873,44.308,438.154,44.308z M482.462,300.308H374.154
                c-24.431,0-44.308-19.876-44.308-44.308s19.876-44.308,44.308-44.308h108.308V300.308z M482.462,182.154H374.154
                c-40.719,0-73.846,33.127-73.846,73.846s33.127,73.846,73.846,73.846h108.308v64c0,24.431-19.876,44.308-44.308,44.308H73.846
                c-24.431,0-44.308-19.876-44.308-44.308V118.154c0-24.431,19.876-44.308,44.308-44.308h364.308
                c24.431,0,44.308,19.876,44.308,44.308V182.154z" />
              </g>
              <g>
                <path d="M393.846,241.231h-19.692c-8.157,0-14.769,6.613-14.769,14.769c0,8.157,6.613,14.769,14.769,14.769h19.692
                c8.157,0,14.769-6.613,14.769-14.769C408.615,247.843,402.003,241.231,393.846,241.231z" />
              </g>
            </svg>
            <span v-if="getAuthStatus" id="current_balance">{{ getAccountInfo.Balance }}</span><span
              id="current_valute">
              {{ getAccountInfo.Currency }}</span>
          </a>
        </div>
        <a class="top_menu_join_btn" @click.prevent="setAuthModal(true)" v-if="!getAuthStatus"><span>{{
          $t("login_page.join_btn") }}</span></a>
        <hr style="margin-left: 10px;" v-if="!getIsMobile">
        <div class="lang-dropdown">
          <span @click="toggeLang" class="current">{{ $i18n.locale }}</span>
          <div v-if="langBlock" class="lang-list">
            <span class="lang-item" :class="{ active: $i18n.locale === lang.code }" @click="setLang(lang.code)"
              v-for="lang in langs" :key="lang.code">
              {{ lang.name }}
            </span>
          </div>
        </div>
        <hr style="margin-left: 10px;" v-if="!getIsMobile">
        <div v-if="!getIsMobile">
          {{ this.$store.getters.getAccountInfo.Email }}
        </div>
      </div>
    </div>
    <!-- <div v-if="getSocketData !== null && getSocketData.Event !== 'activated' && getIsMobile && getReconnectSocket === false" class="bottom"> -->
    <!-- <div class="grid_item">
        <div class="flex_column">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#62A321"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap">
            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
          </svg>
        </div>
        <div class="flex_column">
          {{ getSocketData.Data.Power }} <small> kW</small>
        </div>
      </div>
      <div class="grid_item">
        <div class="flex_column">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#62A321"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
        </div>
        <div class="flex_column">
          {{ formatedDate }}
        </div>
      </div>
      <div class="grid_item">
        <div class="flex_column">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#62A321"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-battery-charging">
            <path d="M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19"></path>
            <line x1="23" y1="13" x2="23" y2="11"></line>
            <polyline points="11 6 7 12 13 12 9 18"></polyline>
          </svg>
        </div>
        <div class="flex_column">
          {{ getSocketData.Data.Import }} <small> kWh</small>
        </div>
      </div>
      <div class="grid_item">
        <div class="flex_column">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#62A321"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign">
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
          </svg>
        </div>
        <div class="flex_column">
          {{ getSocketData.Data.Cost }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from 'axios'

export default {
  name: "TopMenu",
  data() {
    return {
      zoom: 1,
      langBlock: false,
      langs:
        [
          { code: 'bg', name: 'BG' },
          { code: 'en', name: 'EN' },
          { code: 'kz', name: 'KZ' },
          { code: 'ru', name: 'RU' }
        ],
    }
  },
  computed: {
    ...mapGetters(['getBalancePage', 'getRfidPage', 'getHistoryPage', 'getProfilePage', 'getAccountInfo', 'getAuthStatus', 'getIsMobile', 'getMobileMenuStatus', 'getSocketData', 'getReconnectSocket']),
    formatedDate() {
      let x = this.getSocketData.Data._duration
      x = x.substring(0, x.length - 3);
      return x
    }
  },
  methods: {
    toggleZoom() {
      switch (this.zoom) {
        case 1:
          this.zoom = 0.7;
          document.body.style.zoom = this.zoom;
          break
        case 0.7:
          this.zoom = 0.5;
          document.body.style.zoom = this.zoom;
          break
        case 0.5:
          this.zoom = 1;
          document.body.style.zoom = this.zoom;
          break
        default:
          this.zoom = 1;
          document.body.style.zoom = this.zoom;
          break
      }
    },
    setLang(lang) {
      this.langBlock = !this.langBlock
      let _this = this
      if (this.$i18n.locale !== lang) {
        localStorage.setItem('SessionLang', lang)
        this.$i18n.locale = lang
        let updateProfileLangUrl = process.env.VUE_APP_API_URL + '/app/besp/account/update_profile/?format=json&f_token=' + localStorage.getItem('AuthToken') + '&lang=' + lang
        axios({
          url: 'https://voltspot.net/url/cors.php',
          method: 'POST',
          params: {
            url: updateProfileLangUrl
          }
        })
          .then(resp => {
            if (resp.data.rcode >= 0) {
              _this.$store.dispatch('gotAccountInfo')
              _this.$store.dispatch('gotStations')
            }
          })
      }
    },
    toggeLang() {
      this.langBlock = !this.langBlock
    },
    openMobileMenu() {
      this.setMainPage()
      this.$store.commit('setMobileMenuStatus', true)
      let browser = window.navigator.userAgent.substr(-40,)
      let firefox = browser.indexOf('Firefox')
      let mi = browser.indexOf('MiuiBrowser')
      if (firefox >= 0 || mi >= 0) {
        setTimeout(() => document.querySelector('.mobile_menu').style.background = 'rgba(255, 255, 255, 0.95)', 10)
      }
    },
    setMainPage() {
      this.$store.commit('setMainPage')
    },
    setBalancePage() {
      this.$store.commit('setBalancePage')
    },
    setHistoryPage() {
      this.$store.commit('setHistoryPage')
    },
    setRfidPage() {
      this.$store.commit('setRfidPage')
    },
    setProfilePage() {
      this.$store.commit('setProfilePage')
    },
    setAuthModal(boolean) {
      this.$store.state.AuthModal = boolean
    },
    setLegend(boolean) {
      this.$store.commit('setLegend', boolean)
    }
  },
  mounted() {
    if (!localStorage.getItem('AuthToken')) {
      this.setAuthModal(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.loupe {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 32px;
    width: 32px;
  }

  span {
    font-weight: bold;
  }
}

.lang-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .current {
    padding: 5px 10px;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
  }

  .lang-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: auto;

    .lang-item {
      padding: 5px 10px;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(5px);
      transition: all .2s ease;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        background: rgba(230, 230, 230, 0.9);
        transition: all .2s ease;
      }

      &.active {
        background: rgba(230, 230, 230, 0.9);
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.top_board {
  transition: transform 0.8s ease;
  z-index: 402;
  user-select: none;
  padding: 0 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  min-height: 40px;
  margin: 0 auto;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  .top {
    height: 40px;
    display: flex;

    .left_block {
      display: flex;
      align-items: center;
      height: inherit;

      a.logo {
        outline: none;
        margin-right: 10px;
        height: 20px;
        top: -2px;
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
          height: 100%;
        }
      }

      ul.top_menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        height: inherit;
        align-items: center;

        li {
          display: flex;
          align-items: center;
          height: inherit;

          &:last-child {
            margin-right: 0;
          }

          a {
            text-decoration: none;
            color: #62A321;
            font-size: 1rem;
            display: flex;
            height: inherit;
            align-items: center;
            padding: 0 10px;
            position: relative;
            overflow: hidden;
            z-index: 2;

            &.active {
              background: #62A321;
              color: #fff;
            }

            &:focus {
              outline: none;
            }

            &::-moz-focus-inner {
              border: 0;
            }

            &:hover {
              background: #62A321;
              color: #fff;
            }
          }
        }
      }

      .mobile_nav_icon {
        z-index: 11;
        width: 30px;
        height: 30px;
        position: relative;
        display: none;
        cursor: pointer;


        .hamburger_icon {
          height: 4px;
          position: relative;
          width: 30px;
          background-color: #62A321;
          transition: all ease 0.3s;

          &::before,
          &::after {
            content: "";
            position: absolute;
            height: inherit;
            border-radius: inherit;
            background-color: inherit;
            margin: auto;
            width: 60%;
            transition: all ease 0.3s;
          }

          &::before {
            top: -10px;
            left: 0;
            transform-origin: left;
          }

          &::after {
            bottom: -10px;
            right: 0;
            transform-origin: right;
          }
        }
      }

    }

    .right_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      hr {
        width: 1px;
        border: none;
        background-color: #62A321;
        height: 40px;
        position: relative;
        margin: 0 10px 0 0;
      }

      .balance_block {
        a {
          text-decoration: none;
          outline: none;
          display: flex;
          align-items: center;

          svg {
            max-width: 30px;
            margin-right: 5px;
          }

          #current_balance {
            color: #62A321;
            margin-right: 3px;
            font-weight: 500;
            font-size: 1rem;
          }

          #current_valute {
            color: #62A321;
            font-weight: 500;
          }
        }
      }

      a.top_menu_join_btn {
        border: 1px solid #62A321;
        border-radius: 3px;
        font-size: 1rem;
        line-height: 1rem;
        padding: 5px 20px;
        position: relative;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        color: #62A321;
        display: block;
        text-decoration: none;

        span {
          z-index: 20;
        }
      }
    }
  }

  .bottom {
    transition: 3s ease;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
    color: #62A321;
    font-weight: 500;

    .grid_item {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 5px;
      }

      .flex_column {

        small {
          font-size: 70%;
        }
      }
    }
  }
}

//MEDIA STYLES
@media screen and (max-width: 991px) {
  .top_board {
    padding: 0 30px;
    border-radius: 0;

    .top {
      justify-content: space-between;

      .left_block {

        .mobile_nav_icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        a.logo {
          height: 30px;

          img.desktop_logo {
            display: none;
          }
        }

        ul.top_menu {
          display: none;
        }
      }

      .right_block {
        hr {
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .top_board {
    padding: 0 15px;

    .top {
      width: 100%;

      .left_block {
        a.logo {
          margin-right: 0;
          top: 0;
        }
      }

      .right_block {
        hr {
          margin: 0 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .top_board {
    .top {
      .left_block {
        a.logo {
          display: none;
        }
      }
    }
  }
}
</style>
