<template>
  <transition name="fade">
    <div class="login_page_overlay">

      <!-- Reg popup -->
      <transition name="slide-fade">
        <div v-if="RegModal" @touchend.self="successRegMessageReject"
             @mousedown.self="successRegMessageReject"
             class="reg_popup_overlay">
          <div class="reg_popup">
            <div class="modal_header">
            <h3>{{ $t("login_page.reg_popup.title") }}</h3>
            <svg style="cursor: pointer;" @click="successRegMessageReject" height="15px" viewBox="0 0 311 311.07733" width="15px" xmlns="http://www.w3.org/2000/svg">
              <path fill="#000" d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"></path>
              <path fill="#000" d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"></path>
            </svg>
            </div>
            <form @submit.prevent="registerUser" autocomplete="off">
              <input autocomplete="off" :placeholder="placeholderEmail" @input="abortErrorMessage" required
                     id="reg_form_email" v-model="reg_form_email"
                     type="email"
                     name="reg_form_email">
              <div class="password_input">
                <input autocomplete="off" :type="regPasswordShow ? 'text' : 'password'"
                       :placeholder="placeholderPassword" required id="reg_form_password"
                       v-model="reg_form_password"
                       name="reg_form_password">
                <div @click="regPasswordShow = !regPasswordShow" class="eye_block">
                  <svg v-if="!regPasswordShow" class="eye"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
                  </svg>
                  <svg v-else class="eye" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 20 20">
                    <path
                        d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"/>
                  </svg>
                </div>
              </div>
              <span class="validate_reject"
                    v-if="reg_form_password && RegFormValidated === false">{{ regPasswordValidation }}</span>
              <input autocomplete="off" :placeholder="placeholderPasswordConfirm" required
                     id="reg_form_confirm_password" v-model="reg_form_confirm_password"
                     :type="regPasswordShow ? 'text' : 'password'"
                     name="reg_form_confirm_password">
              <span class="validate_reject" v-if="reg_form_confirm_password && RegFormValidated === false">{{
                  regConfirmPasswordValidation
                }}</span>
              <vue-tel-input @validate="phoneObject" showDialCode="false" autocomplete="off" defaultCountry="bg" @input="abortErrorMessage" v-bind="phoneInput" id="reg_form_phone"
                             v-model="reg_form_phone" type="tel" name="reg_form_phone"></vue-tel-input>
              <!-- <input autocomplete="off" placeholder="VAT-ID" id="reg_form_vatid" v-model="reg_form_vatid" type="text" name="reg_form_vatid"> -->
              <button type="submit" :disabled="!RegFormValidated || this.reg_form_email === '' || !this.phoneIsValid"
                      @keyup.enter="registerUser">{{
                  $t("login_page.reg_popup.submit_btn")
                }}
              </button>
            </form>
            <h4 class="reg_success_message" v-if="successRegMessage">{{
                $t("login_page.reg_popup.reg_success_message")
              }}</h4>
            <h4 class="reg_error_message" v-if="RegErrorEmail !== null">{{ RegErrorEmail }}</h4>
            <h4 class="reg_error_message" v-if="RegErrorPhone">{{ RegErrorPhone }}</h4>
            <div style="display: none;" class="soc_reg">
              <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                  :title="$t('login_page.reg_popup.soc_btn_fb')" src="/images/sn_fb.svg"
                  alt=""></a>
              <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                  :title="$t('login_page.reg_popup.soc_btn_google')"
                  src="/images/sn_google.svg"
                  alt=""></a>
              <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                  :title="$t('login_page.reg_popup.soc_btn_mailru')"
                  src="/images/sn_mailru.svg"
                  alt=""></a>
              <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                  :title="$t('login_page.reg_popup.soc_btn_ok')" src="/images/sn_ok.svg"
                  alt=""></a>
              <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                  :title="$t('login_page.reg_popup.soc_btn_vk')" src="/images/sn_vk.svg"
                  alt=""></a>
              <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                  :title="$t('login_page.reg_popup.soc_btn_ya')" src="/images/sn_yandex.svg"
                  alt=""></a>
            </div>
            <hr>
            <button @click="showJoinModal" class="open_join_popup_btn">{{
                $t("login_page.recover_popup.go_login_btn")
              }}
            </button>
          </div>
        </div>
      </transition>

      <!-- Join popup -->
      <transition name="slide-fade">
        <div v-if="JoinModal" @touchend.self="setAuthModal(false)" @mousedown.self="setAuthModal(false)"
             class="join_popup_overlay">
          <div class="join_popup">
            <div v-if="!login_success" class="join_popup_inner">
              <div class="modal_header">
                <h3>{{ $t("login_page.join_popup.title") }}</h3>
                <svg style="cursor: pointer;" @click="setAuthModal(false)" height="15px" viewBox="0 0 311 311.07733" width="15px" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000" d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"></path>
                <path fill="#000" d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"></path></svg>
              </div>
              <form autocomplete="on" @submit.prevent="loginUser">
                <input id="join_form_email" @input="AuthErrorMessage = null" :placeholder="placeholderEmail"
                       v-model="join_form_email" type="email"
                       name="join_form_email"
                       autocomplete="on"
                       required>
                <div class="password_input">
                  <input id="join_form_password" @input="AuthErrorMessage = null" :placeholder="placeholderPassword"
                         v-model="join_form_password" :type="joinPasswordShow ? 'text' : 'password'"
                         name="join_form_password" autocomplete="on" required>
                  <div @click="joinPasswordShow = !joinPasswordShow" class="eye_block">
                    <svg v-if="!joinPasswordShow" class="eye"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path
                          d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
                    </svg>
                    <svg v-else class="eye" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20">
                      <path
                          d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"/>
                    </svg>
                  </div>
                </div>
                <button class="join_btn" type="submit" @keyup.enter="loginUser">{{
                    $t("login_page.join_popup.submit_btn")
                  }}
                </button>
                <div style="display: flex; justify-content: space-between; margin: 20px 0 10px 0">
                  <button @click.prevent="RegModalOpen" style="font-weight: 600;" class="reg_btn">
                    {{ $t("login_page.reg_btn") }}
                  </button>
                  <button type="button" @click.prevent="showRecoverModal" style="font-weight: 600;" class="recover_pass_btn">
                    {{ $t("login_page.join_popup.go_recover_btn") }}
                  </button>
                </div>
                <transition name="fade">
                  <span v-if="AuthErrorMessage !== null" class="error_message">{{ ErrorLogin }}</span>
                </transition>
              </form>
              <div style="display: none;" class="soc_auth">
                <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                    :title="$t('login_page.join_popup.soc_btn_fb')" src="/images/sn_fb.svg"
                    alt=""></a>
                <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                    :title="$t('login_page.join_popup.soc_btn_google')"
                    src="/images/sn_google.svg"
                    alt=""></a>
                <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                    :title="$t('login_page.join_popup.soc_btn_mailru')"
                    src="/images/sn_mailru.svg"
                    alt=""></a>
                <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                    :title="$t('login_page.join_popup.soc_btn_ok')" src="/images/sn_ok.svg"
                    alt=""></a>
                <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                    :title="$t('login_page.join_popup.soc_btn_vk')" src="/images/sn_vk.svg"
                    alt=""></a>
                <a disabled="true" style="filter:grayscale(100%);pointer-events: none;" href=""><img
                    :title="$t('login_page.join_popup.soc_btn_ya')" src="/images/sn_yandex.svg"
                    alt=""></a>
              </div>
            </div>
            <div v-if="login_success" class="success_login">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 98.5 98.5" enable-background="new 0 0 98.5 98.5" xml:space="preserve">
                <path fill="none" stroke-width="8" stroke-miterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4
                C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/>
              </svg>
            </div>
          </div>
        </div>
      </transition>

      <!-- Recover popup -->
      <transition name="slide-fade">
        <div v-if="RecoverModal" @touchend.self="setAuthModal(false)" @mousedown.self="setAuthModal(false)"
             class="recover_popup_overlay">
          <div class="recover_popup">
            <div class="modal_header">
            <h3>{{ $t("login_page.recover_popup.title") }}</h3>
            <svg style="cursor: pointer;" @click="setAuthModal(false)" height="15px" viewBox="0 0 311 311.07733" width="15px" xmlns="http://www.w3.org/2000/svg">
              <path fill="#000" d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"></path>
              <path fill="#000" d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"></path></svg>
            </div>
            <form @submit.prevent="recoverPassword">
              <input @input="PasswordRecoveredMessage = null, DisableRecoverBtn = false" :placeholder="placeholderEmail"
                     id="recover_form_email" v-model="recover_form_email" type="email"
                     name="recover_form_email" required>
              <button type="submit" :disabled="DisableRecoverBtn || recover_form_email === ''" class="recover_btn"
                      @keyup.enter="recoverPassword">{{
                  $t("login_page.recover_popup.submit_btn")
                }}
              </button>
              <!-- <span v-if="PasswordRecoveredMessage !== null" class="recover_error_message">
                {{ PasswordRecoveredMessage }}
              </span> -->
              <span v-if="PasswordRecoveredMessage !== null && checkLang() == 'bg'" class="recover_error_message">
                  Имейл за възстановяване на достъпа е изпратен. Проверете посочената пощенска кутия.
              </span>
              <span v-if="PasswordRecoveredMessage !== null && checkLang() == 'en'" class="recover_error_message">
                  Your access recovery email is heading your way. Check the mailbox.
              </span>
              <span v-if="PasswordRecoveredMessage !== null && checkLang() == 'ru'" class="recover_error_message">
                  Письмо для восстановления доступа отправлено Вам. Проверьте указанный почтовый ящик.
              </span>
              <span v-if="PasswordRecoveredMessage !== null && checkLang() == 'kz'" class="recover_error_message">
                Kırudı qalpyna keltıru haty sızge jıberıldı. Körsetılgen poşta jäşıgın tekserıñız.
              </span>
            </form>
            <hr>
            <button @click="showJoinModal" type="button" class="open_join_popup_btn">{{
                $t("login_page.recover_popup.go_login_btn")
              }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from 'vuex'
import {VueTelInput} from 'vue-tel-input'
import axios from "axios"

export default {
  name: "AuthModal",
  components: {
    VueTelInput
  },
  data: function () {
    return {
      phoneIsValid: null,
      login_success: false,
      regPasswordShow: false,
      joinPasswordShow: false,
      JoinModal: true,
      RegModal: false,
      RecoverModal: false,
      reg_form_email: '',
      reg_form_vatid: '',
      reg_form_password: '',
      reg_form_confirm_password: '',
      reg_form_phone: '',
      phoneInput: {
        mode: 'international',
        placeholder: '',
        autoFormat: false,
        validCharactersOnly: true,
        enabledCountryCode: true,
        inputOptions: {
          showDialCode: false
        },
        autoDefaultCountry: true,
        // defaultCountry: "BG",
      },
      join_form_password: '',
      join_form_email: '',
      recover_form_email: '',
      AuthErrorMessage: null,
      RegErrorEmail: null,
      RegErrorPhone: null,
      successRegMessage: false,
      RegErrorMessage: false,
      RegFormValidated: false,
      PasswordRecoveredMessage: null,
      DisableRecoverBtn: false,
    }
  },
  computed: {
    ...mapGetters(['UserLogged', 'LoginPageOverlay', 'RegPopup', 'JoinPopup', 'RecoverPopup', 'SessionToken']),
    ErrorLogin() {
      if (this.AuthErrorMessage === null) {
        return ''
      } else {
        return this.AuthErrorMessage
      }
    },
    placeholderPasswordConfirm() {
      let cookLang = localStorage.getItem('SessionLang')
      if (cookLang === 'ru') {
        return 'Повторите пароль'
      } else if (cookLang === 'en') {
        return 'Confirm password'
      } else if (cookLang === 'kz') {
        return 'Paróldi qaıtalańyz'
      } else {
        return 'Подтвърдете парола'
      }
    },
    placeholderEmail() {
      let cookLang = localStorage.getItem('SessionLang')
      if (cookLang === 'ru') {
        return 'Почта'
      } else if (cookLang === 'en') {
        return 'Email'
      } else if (cookLang === 'kz') {
        return 'Poshta'
      } else {
        return 'Поща'
      }
    },
    placeholderPassword() {
      let cookLang = localStorage.getItem('SessionLang')
      if (cookLang === 'ru') {
        return 'Пароль'
      } else if (cookLang === 'en') {
        return 'Password'
      } else if (cookLang === 'kz') {
        return 'Paról'
      } else {
        return 'Парола'
      }
    },
    regPasswordValidation() {
      if (this.reg_form_password.length < 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.RegFormValidated = false
        let cookLang = localStorage.getItem('SessionLang')
        if (cookLang === 'ru') {
          return 'Пароль должен содержать минимум 8 символов'
        } else if (cookLang === 'en') {
          return 'Password must be at least 8 characters long'
        } else if (cookLang === 'kz') {
          return 'Paról kem degende 8 tańbany qamtýy tıis'
        } else {
          return 'Паролата трябва да е най-малко 8 знака'
        }

      }
      if (this.reg_form_password !== this.reg_form_confirm_password) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.RegFormValidated = false
        let cookLang = localStorage.getItem('SessionLang')
        if (cookLang === 'ru') {
          return "Пароли не совпадают"
        } else if (cookLang === 'en') {
          return "Passwords do not match"
        } else if (cookLang === 'kz') {
          return "Parólder sáıkes kelmeıdi"
        } else {
          return "Паролите не совпадат"
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.RegFormValidated = false
      return ""
    },
    regConfirmPasswordValidation() {
      if (this.reg_form_confirm_password.length < 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.RegFormValidated = false
        let cookLang = localStorage.getItem('SessionLang')
        if (cookLang === 'ru') {
          return 'Пароль должен содержать минимум 8 символов'
        } else if (cookLang === 'en') {
          return 'Password must be at least 8 characters long'
        } else if (cookLang === 'kz') {
          return 'Paról kem degende 8 tańbany qamtýy tıis'
        } else {
          return 'Паролата трябва да е най-малко 8 знака'
        }
      }
      if (this.reg_form_password !== this.reg_form_confirm_password) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.RegFormValidated = false
        let cookLang = localStorage.getItem('SessionLang')
        if (cookLang === 'ru') {
          return "Пароли не совпадают"
        } else if (cookLang === 'en') {
          return "Passwords do not match"
        } else if (cookLang === 'kz') {
          return "Parólder sáıkes kelmeıdi"
        } else {
          return "Паролите не совпадат"
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.RegFormValidated = true
      return ""
    },
  },
  methods: {
    phoneObject(object) {
      if (object.valid === true) {
        this.phoneIsValid = true;
      } else {
        this.phoneIsValid = false;
      }
    },
    checkLang() {
      return localStorage.getItem("SessionLang");
    },
    setAuthModal(boolean) {
      this.JoinModal = false
      this.RecoverModal = false
      this.$store.state.AuthModal = boolean
    },
    loginUser() {
      let _this = this
      let email = this.join_form_email
      let password = this.join_form_password
      let current_lang = localStorage.getItem('SessionLang')
      let loginUrl = process.env.VUE_APP_API_URL + '/app/besp/account/auth/?format=json&f_login=' + email + '&f_password=' + encodeURIComponent(password) + '&lang=' + current_lang
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: loginUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode > 0) {
              _this.login_success = true
              let token = resp.data.rdata.token
              localStorage.setItem('AuthToken', token)
              _this.$store.dispatch('gotAccountInfo')
              _this.$store.commit('setAuthStatus', true)
              _this.$store.dispatch('initSocket')
              // let hToken = localStorage.setItem('AuthToken', token)
              // _this.$store.dispatch('initSocket', hToken)
              setTimeout(() => _this.JoinModal = false, 2000)
              setTimeout(() => _this.$store.commit('setAuthModal', false), 2000)
            } else {
              _this.AuthErrorMessage = resp.data.rtext
            }
          })
          .catch()
    },
    registerUser() {
      let _this = this
      const regexPhone = /[+  -]*/g
      let registerUrl
      this.RegFormValidated = false
      let email = this.reg_form_email
      let vatid = this.reg_form_vatid
      let password = this.reg_form_password
      let phone = this.reg_form_phone.replace(regexPhone, '')
      let current_lang = localStorage.getItem('SessionLang')
      // if (phone.length > 6) {
        registerUrl = process.env.VUE_APP_API_URL + '/app/besp/account/register/?format=json&f_email=' + email + '&f_password=' + encodeURIComponent(password) + '&f_phone=' + phone + '&f_vatid=' + vatid + '&lang=' + current_lang
      // } else {
        // registerUrl = process.env.VUE_APP_API_URL + '/app/besp/account/register/?format=json&f_email=' + email + '&f_password=' + password + '&lang=' + current_lang
      // }
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: registerUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode > 0) {
              _this.successRegMessage = true
              _this.RegErrorMessage = false
              _this.RegErrorEmail = null
              _this.RegErrorPhone = null
              _this.reg_form_email = ''
              _this.reg_form_password = ''
              _this.reg_form_confirm_password = ''
              _this.reg_form_phone = ''

            if(resp.data.rdata.Token) {
              _this.login_success = true
              let token = resp.data.rdata.Token
              localStorage.setItem('AuthToken', token)
              _this.$store.dispatch('gotAccountInfo')
              _this.$store.commit('setAuthStatus', true)
              _this.$store.dispatch('initSocket')
              setTimeout(() => _this.RegModal = false, 1000)
              setTimeout(() => _this.$store.commit('setAuthModal', false), 1000)
            }

            } else {
              _this.RegFormValidated = true
              if (resp.data.rsysd.ERROR_PARAMS.f_email !== 'OK') {
                _this.RegErrorEmail = resp.data.rsysd.ERROR_PARAMS.f_email
              }
              if (resp.data.rsysd.ERROR_PARAMS.f_phone !== 'OK') {
                _this.RegErrorPhone = resp.data.rsysd.ERROR_PARAMS.f_phone
              }
              _this.successRegMessage = false
            }
          })
          .catch(err => {
            console.log('Ошибка AXIOS (отправки регистрации): ' + err)
            _this.RegFormValidated = true
          })
    },
    recoverPassword() {
      let _this = this
      let email = this.recover_form_email
      let current_lang = localStorage.getItem('SessionLang')
      let RecoverUrl = process.env.VUE_APP_API_URL + '/app/besp/account/recovery_request/?format=json&f_login=' + email + '&lang=' + current_lang
      this.DisableRecoverBtn = true
      axios({
        url: 'https://voltspot.net/url/cors.php',
        method: 'POST',
        params: {
          url: RecoverUrl
        }
      })
          .then(resp => {
            if (resp.data.rcode > 0) {
              _this.PasswordRecoveredMessage = resp.data.rtext
              _this.DisableRecoverBtn = true
              setTimeout(() => document.querySelector('.recover_error_message').style.color = '#62a321', 10)
            } else {
              _this.PasswordRecoveredMessage = resp.data.rtext
              _this.DisableRecoverBtn = true
              setTimeout(() => document.querySelector('.recover_error_message').style.color = 'red', 10)
            }
          })
          .catch(err => {
            console.log('Ошибка запроса изменения пароля: ' + err)
          })
    },
    abortErrorMessage() {
      this.RegErrorEmail = null
      this.RegErrorPhone = null
    },
    showRecoverModal() {
      this.JoinModal = false
      this.RecoverModal = true
    },
    showJoinModal() {
      this.RecoverModal = false
      this.successRegMessage = false
      this.RegModal = false
      this.JoinModal = true
      this.recover_form_email = ''
      this.PasswordRecoveredMessage = null
    },
    RegModalOpen() {
      this.JoinModal = false
      this.RegModal = true
    },
    successRegMessageReject() {
      this.successRegMessage = false
      this.RegModal = false
      this.$store.state.AuthModal = false
    }
  }
}
</script>

<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .6s ease;
}

.slide-fade-leave-active {
  transition: all .6s ease;
}

.slide-fade-enter {
  transform: rotateY(180deg);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: rotateY(-180deg);
  opacity: 0;
}

.login_page_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.6);
  z-index: 402;
  backdrop-filter: blur(1px);

  .modal_header {
    display: flex;
    grid-gap: 15px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .reg_popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .reg_popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: 100%;
      max-width: 350px;
      max-height: 600px;
      background: #fff;
      border-radius: 5px;
      padding: 15px;
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);

      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1;
        color: #62a321;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
      }

      hr {
        margin: 10px 0;
      }

      form {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        span.validate_reject {
          margin: 10px 0 0 0;
          line-height: 1;
          text-align: center;
          color: red;
          font-size: 0.8rem;
          font-weight: 400;
        }

        input {
          width: 100%;
          outline: none;
          background: #fff;
          color: #000;
          padding-left: 5px;
          line-height: 1;
          height: 30px;
          border-radius: 3px;
          font-size: 1rem;
          font-weight: 400;
          margin-top: 10px;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &::placeholder {
            font-size: 0.8rem;
          }

          &:first-child {
            margin-top: 0;
          }
        }

        button {
          margin-top: 10px;
          border-radius: 3px;
          border: none;
          background: #62a321;
          outline: none;
          line-height: 1;
          display: flex;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          height: 35px;
          color: #fff;
          font-size: 1rem;
          transition: all .2s linear;
          box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

          &:disabled {
            background: #80808087;
            color: #fff;
            border-color: transparent;
          }
        }

        .password_input {
          margin-top: 10px;
          position: relative;
          padding-right: 30px;

          input {
            border-right: none;
            border-radius: 5px 0 0 5px;
          }

          .eye_block {
            width: 30px;
            right: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0 5px 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg.eye {
              width: 20px;
              height: 20px;

              path {
                fill: gray
              }
            }
          }
        }

        .vue-tel-input {
          width: 100% !important;
          outline: none !important;
          background: #fff !important;
          color: #000 !important;
          line-height: 1 !important;
          height: 30px !important;
          border-radius: 3px !important;
          font-size: 1rem !important;
          font-weight: 400 !important;
          margin-top: 10px !important;
          border: 1px solid rgba(0, 0, 0, 0.2) !important;
          box-shadow: none !important;

          * {
            border: none !important;
            outline: none !important;
          }

          .vti__dropdown {
            border: none !important;
            outline: none !important;
            color: red !important;
          }

          .vti__dropdown.open {
            border: none !important;
            outline: none !important;

            &:focus {
              border: none !important;
              outline: none !important;
            }
          }
        }
      }

      .soc_reg {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          img {
            width: 25px;
          }
        }
      }

      button.open_join_popup_btn {
        margin-top: 5px;
        border: none;
        background: transparent;
        outline: none;
        color: gray;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .reg_success_message {
      color: #62a321;
      margin: 10px 0;
      line-height: 1;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 400;
    }

    .reg_error_message {
      margin: 10px 0 0 0;
      line-height: 1;
      text-align: center;
      color: red;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  .join_popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .join_popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: 100%;
      max-width: 350px;
      max-height: 600px;
      background: #fff;
      border-radius: 5px;
      padding: 15px;
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);

      .join_popup_inner {
        hr {
          margin: 10px 0;
        }

        h3 {
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 1;
          color: #62a321;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0;
        }

        form {
          display: flex;
          flex-direction: column;

          span.error_message {
            margin: 10px 0 0 0;
            line-height: 1;
            text-align: center;
            color: red;
            font-size: 0.8rem;
            font-weight: 400;
          }

          input {
            width: 100%;
            outline: none;
            background: #fff;
            color: #000;
            padding-left: 5px;
            line-height: 1;
            height: 30px;
            border-radius: 3px;
            font-size: 1rem;
            font-weight: 400;
            margin-top: 10px;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &::placeholder {
              font-size: 0.8rem;
            }

            &:first-child {
              margin-top: 0;
            }
          }

          .password_input {
            margin-top: 10px;
            position: relative;
            padding-right: 30px;

            input {
              border-right: none;
              border-radius: 5px 0 0 5px;
            }

            .eye_block {
              width: 30px;
              right: 0;
              position: absolute;
              top: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.1);
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 0 5px 5px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              svg.eye {
                width: 20px;
                height: 20px;

                path {
                  fill: gray
                }
              }
            }
          }

          button.join_btn {
            margin-top: 5px;
            border-radius: 3px;
            border: none;
            background: #62a321;
            outline: none;
            line-height: 1;
            display: flex;
            font-weight: 600;
            align-items: center;
            justify-content: center;
            height: 35px;
            color: #fff;
            font-size: 1rem;
            transition: all .2s linear;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
          }
        }

        .soc_auth {
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
            img {
              width: 25px;
            }
          }
        }

        button.recover_pass_btn {
          padding: 0;
          border: none;
          background: transparent;
          outline: none;
          color: gray;
          padding-left: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1rem;
          line-height: 1;

          &:hover {
            text-decoration: underline;
          }
        }

        button.reg_btn {
          padding: 0;
          border: none;
          background: transparent;
          outline: none;
          color: gray;
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .success_login {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 150px;
          height: auto;

          path {
            stroke: #62a321;;
            stroke-dashoffset: 745.74853515625;
            stroke-dasharray: 745.74853515625;
            animation: dash 2s ease-out forwards;
          }
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: 745.74853515625;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
      }
    }
  }

  .recover_popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .recover_popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: 100%;
      max-width: 350px;
      max-height: 600px;
      background: #fff;
      border-radius: 5px;
      padding: 15px;
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);

      hr {
        margin: 10px 0;
      }

      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1;
        color: #62a321;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
      }

      form {
        display: flex;
        flex-direction: column;

        .recover_error_message {
          margin: 10px 0 0 0;
          font-size: 0.8rem;
          text-align: center;
          color: #000;
        }

        input {
          width: 100%;
          outline: none;
          background: #fff;
          color: #000;
          padding-left: 5px;
          line-height: 1;
          height: 30px;
          border-radius: 3px;
          font-size: 1rem;
          font-weight: 400;
          margin-top: 10px;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &::placeholder {
            font-size: 0.8rem;
          }

          &:first-child {
            margin-top: 0;
          }
        }

        button.recover_btn {
          margin-top: 10px;
          border-radius: 3px;
          border: none;
          background: #62a321;
          outline: none;
          line-height: 1;
          display: flex;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          height: 35px;
          color: #fff;
          font-size: 1rem;
          transition: all .2s linear;
          box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

          &:disabled {
            background: #80808087;
            color: #fff;
            border-color: transparent;
          }
        }

      }

      button.open_join_popup_btn {
        border: none;
        background: transparent;
        outline: none;
        color: gray;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        line-height: 1;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

}

//MEDIA STYLES
@media screen and (max-width: 1445px) {
  .login_page_outer {
    .login_page_overlay {
      .login_page_inner {
        .login_page_inner_left {
          padding-left: 40px;
          width: 65%;

          h1 {
            width: 100%;
          }

          h3 {
            width: 80%;
          }
        }

        .login_page_inner_right {
          padding-right: 40px;
          width: 35%;
          align-items: flex-start;

          h2.test {
            font-size: 8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .login_page_outer {
    .login_page_overlay {
      .login_page_inner {
        .login_page_inner_left {
          h1 {
            font-size: 4rem;
          }
        }

        .login_page_inner_right {
          h2.test {
            font-size: 6.2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .login_page_outer {
    .login_page_overlay {
      .login_page_inner {
        .login_page_inner_left {
          width: 100%;

          h1 {
            width: 100%;
            font-size: 4rem;
          }

          h3 {
            width: 80%;
          }
        }

        .login_page_inner_right {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .login_page_outer {
    .login_page_overlay {
      .login_page_inner {
        .login_page_inner_left {
          h3 {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .login_page_overlay {
    .join_popup_overlay {
      .join_popup {
        max-width: 90vw;

        .join_popup_inner {
          form {
            margin-bottom: 15px;

            input {
              height: 45px;
            }

            button.join_btn {
              height: 45px;
              margin-top: 10px;
            }

            .password_input {
              padding-right: 50px;

              .eye_block {
                width: 50px;

                svg.eye {
                  width: 25px;
                  height: auto;
                }
              }
            }
          }

          hr {
            margin: 15px 0;
          }
        }
      }
    }

    .reg_popup_overlay {
      .reg_popup {
        max-width: 90vw;

        form {
          margin-bottom: 15px;

          .vue-tel-input {
            height: 45px !important;
          }

          input {
            height: 45px;
          }

          button {
            height: 45px;
            margin-top: 10px;
          }

          .password_input {
            padding-right: 50px;

            .eye_block {
              width: 50px;

              svg.eye {
                width: 25px;
                height: auto;
              }
            }
          }
        }

        hr {
          margin: 15px 0;
        }
      }
    }

    .recover_popup_overlay {
      .recover_popup {
        form {
          input {
            height: 45px;
          }

          button.recover_btn {
            height: 45px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .login_page_outer {
    .login_page_overlay {
      .login_page_inner {
        .login_page_inner_left {
          h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

</style>
